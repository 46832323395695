import React from 'react';
import {isMobile} from "react-device-detect";

import styles from './Tabs.scss';
import classNames from "classnames";
const cx = classNames.bind(styles);

const Tabs = ({tabs, currentTab, setCurrentTab}) => {
    return (
        <div className={cx(!isMobile? 'culture_btn_wrap' : 'culture_btn_wrap_mobile')}>
            {
                tabs.map((tab, i) => {
                    return (
                        <div
                            className={cx('culture_btn_box', {active: currentTab === tab.name})}
                            key={i}
                        >
                            <button
                                className={cx('culture_btn')}
                                onClick={() => setCurrentTab(tab.name)}
                            >
                                {tab.kor}
                            </button>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default Tabs;
