import React, {useState, useEffect} from 'react';
import {isMobile} from "react-device-detect";

import styles from './MainSlide.scss';
import classNames from "classnames";

const cx = classNames.bind(styles);

const MainSlide = () => {

    const [idx, setIdx] = useState(null);

    // 최초 진입 시
    useEffect(() => {
        setIdx(0);
    }, [])

    // 5초마다 실행
    useEffect(() => {
        if (idx !== null) {
            setTimeout(() => {
                if (idx >= 2) {
                    setIdx(0);
                } else {
                    setIdx(idx => idx + 1);
                }
            }, 5000);
        }
    }, [idx])

    return (
        <div className={cx("MainSlide", {mobile: isMobile})}>
            <ul className={cx("visual_wrap")}>
                <li className={cx("visu_slide", {On: idx === 0})} style={{backgroundImage: `url(main-bg-1.jpg)`}}>
                    <div className={cx("visu_veil", {On: idx === 0})} />
                    <div className={cx("visu_txt_wrap", {On: idx === 0})}>
                        <div className={cx("visu_txt_cont")}>
                            <div className={cx("visu_tit")}>
                                <span>참 하나님</span>의 말씀을 전하며<br/>
                                <span>참 사랑</span>을 실천하는 교회
                            </div>
                            {
                                !isMobile &&
                                <div className={cx("visu_txt_sub01")}>
                                    생명나눔 헌혈 캠페인
                                </div>
                            }
                        </div>
                    </div>
                </li>
                <li className={cx("visu_slide", {On: idx === 1})} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}/main/main_banner_02.jpg`}}>
                    <div className={cx("visu_veil", {On: idx === 1})} />
                    <div className={cx("visu_txt_wrap", {On: idx === 1})}>
                        <div className={cx("visu_txt_cont")}>
                            <div className={cx("visu_tit")}>
                                비상하는 <span>청옥 빛</span><br/>
                                서울야고보
                            </div>
                            {
                                !isMobile &&
                                <div className={cx("visu_txt_sub01")}>
                                    신천지예수교증거장막성전
                                </div>
                            }
                        </div>
                    </div>
                </li>
                <li className={cx("visu_slide", {On: idx === 2})} style={{backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}/main/main_banner_03.jpg`}}>
                    <div className={cx("visu_veil", {On: idx === 2})} />
                    <div className={cx("visu_txt_wrap", {On: idx === 2})}>
                        <div className={cx("visu_txt_cont")}>
                            <div className={cx("visu_tit")}>
                                <span>계시 말씀</span>으로 인 맞아<br/>
                                창조된 <span>12지파</span>
                            </div>
                            {
                                !isMobile &&
                                <div className={cx("visu_txt_sub01")}>
                                    진리의 성읍 아름다운 신천지
                                </div>
                            }
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default MainSlide;