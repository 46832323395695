import React from 'react';
import styles from './Footer.scss';
import classNames from "classnames";
import {isMobile} from "react-device-detect";

const cx = classNames.bind(styles);

const Logo = process.env.REACT_APP_IMAGE_URL + '/logo_white.png';

const Footer = () => {
    return (
        <div className={cx('Footer', {mobile: isMobile})}>
            <div className={cx('content')}>
                <img src={Logo} alt={''}/>
                <div className={cx('contact')}>
                    <div className={cx('address')}>주소: 서울시 노원구 동일로 1323 하라프라자</div>
                    <div className={cx('number')}>대표번호: 070-4441-6956</div>
                </div>
            </div>
        </div>
    );
}

export default Footer;