import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Layout from "../Layout/Layout";

import {isMobile} from "react-device-detect";
import styles from './Neighbor.scss';
import classNames from "classnames";
import PageTitle from "../PageTitle/PageTitle";
const cx = classNames.bind(styles);

const imgUrlOriginPath = `${process.env.REACT_APP_IMAGE_URL}/neighbor`;
const ContentsInfos = [
    {
        title: '방역 봉사',
        mainImgUrl: `${imgUrlOriginPath}/a1.jpg`,
        desc: <>
            코로나 바이러스 유행이 장기화되면서 신천지 자원봉사단은 오해와 편견을 뒤로하고<br/>
            코로나 바이러스로부터 지역사회의 안전을 지키기 위해 2020년 9월부터 6개월간 무료로<br/>
            정기 방역을 실시했습니다. 전국에 있는 각 지부에서 무료 정기 방역봉사가 실시된 가운데<br/>
            서울. 경기동부연합 신천지 자원봉사단은 회원 99명이 28일에 걸쳐 68곳의 정기 방역 봉사를 마쳤습니다.
        </>,
        descMobile: <>
            코로나 바이러스 유행이 장기화되면서 신천지 자원봉사단은 오해와 편견을 뒤로하고
            코로나 바이러스로부터 지역사회의 안전을 지키기 위해 2020년 9월부터 6개월간 무료로
            정기 방역을 실시했습니다. 전국에 있는 각 지부에서 무료 정기 방역봉사가 실시된 가운데
            서울. 경기동부연합 신천지 자원봉사단은 회원 99명이 28일에 걸쳐 68곳의 정기 방역 봉사를 마쳤습니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/a2.jpg`,
            `${imgUrlOriginPath}/a3.jpg`,
            `${imgUrlOriginPath}/a4.jpg`,
            `${imgUrlOriginPath}/a5.jpg`,
            `${imgUrlOriginPath}/a6.jpg`,
        ]
    },
    {
        title: '착한기부 착한소비 캠페인',
        mainImgUrl: `${imgUrlOriginPath}/b1.jpg`,
        desc: <>
            착한기부 착한소비 캠페인은 코로나19로 어려움을 겪고 있는 소외된 이웃을 위한 캠페인 입니다.<br/>
            선정된 ‘착한 가게’에서 신천지 성도가 생활 소비를 하면 가게 수입금 3-5%를<br/>
            소외계층에 기부하는 활동입니다. 신천지 자원봉사단은 정기적으로 ‘착한 가게’를 홍보하여,<br/>
            일상에서 필요한 소비를 하면서 어려운 이웃도 도울 수 있는 착한 소비를 권장했습니다.
        </>,
        descMobile: <>
            착한기부 착한소비 캠페인은 코로나19로 어려움을 겪고 있는 소외된 이웃을 위한 캠페인 입니다.
            선정된 ‘착한 가게’에서 신천지 성도가 생활 소비를 하면 가게 수입금 3-5%를
            소외계층에 기부하는 활동입니다. 신천지 자원봉사단은 정기적으로 ‘착한 가게’를 홍보하여,
            일상에서 필요한 소비를 하면서 어려운 이웃도 도울 수 있는 착한 소비를 권장했습니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/b2.jpg`,
            `${imgUrlOriginPath}/b3.jpg`,
            `${imgUrlOriginPath}/b4.jpg`,
            `${imgUrlOriginPath}/b5.jpg`,
            `${imgUrlOriginPath}/b6.jpg`,
        ]
    },
    {
        title: '백세만세',
        mainImgUrl: `${imgUrlOriginPath}/c1.jpg`,
        desc: <>
            신천지 자원봉사단의 3대 정기 봉사 중 하나.<br/>
            노인시설에서 생활하시는 어르신들을 대상으로 보건, 영양, 정신적으로 도움을 줄 수 있는<br/>
            다양한 프로그램을 실시합니다. 어르신들이 오늘날 대한민국의 존재와 발전에 기여해 온 바에 대해<br/>
            존경받을 수 있는 사회적 분위기를 조성하고 고령화 시대에 야기되는 현실적 문제 해결을 위해<br/>
            노력하고 있습니다.
        </>,
        descMobile: <>
            신천지 자원봉사단의 3대 정기 봉사 중 하나.
            노인시설에서 생활하시는 어르신들을 대상으로 보건, 영양, 정신적으로 도움을 줄 수 있는
            다양한 프로그램을 실시합니다. 어르신들이 오늘날 대한민국의 존재와 발전에 기여해 온 바에 대해
            존경받을 수 있는 사회적 분위기를 조성하고 고령화 시대에 야기되는 현실적 문제 해결을 위해
            노력하고 있습니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/c2.jpg`,
            `${imgUrlOriginPath}/c3.jpg`,
            `${imgUrlOriginPath}/c4.jpg`,
            `${imgUrlOriginPath}/c5.jpg`,
            `${imgUrlOriginPath}/c6.jpg`,
        ]
    },
    {
        title: '핑크보자기',
        mainImgUrl: `${imgUrlOriginPath}/d1.jpg`,
        desc: <>
            홀로 노인, 장애인 가정, 조손 가정, 소년 소녀 가장 들을 대상으로 하는 식생활 개선 프로젝트.<br/>
            전문 영양사의 식단으로 만든 가정식 도시락을 직접 배달하면서 생활환경 조사를 통해 주거환경<br/>
            개선 개보수도 실시하고 있습니다. 정신적 문제 해결 멘토링 서비스도 함께 진행하면서<br/>
            사회 취약계층의 다양한 문제 해결을 위해 노력합니다.
        </>,
        descMobile: <>
            홀로 노인, 장애인 가정, 조손 가정, 소년 소녀 가장 들을 대상으로 하는 식생활 개선 프로젝트.
            전문 영양사의 식단으로 만든 가정식 도시락을 직접 배달하면서 생활환경 조사를 통해 주거환경
            개선 개보수도 실시하고 있습니다. 정신적 문제 해결 멘토링 서비스도 함께 진행하면서
            사회 취약계층의 다양한 문제 해결을 위해 노력합니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/d2.jpg`,
            `${imgUrlOriginPath}/d3.jpg`,
            `${imgUrlOriginPath}/d4.jpg`,
            `${imgUrlOriginPath}/d5.jpg`,
            `${imgUrlOriginPath}/d6.jpg`,
        ]
    },
    {
        title: '다다익선',
        mainImgUrl: `${imgUrlOriginPath}/e1.jpg`,
        desc: <>
            다다익선(多多益善) 프로젝트는 힐링·나눔·기부·환경을 주제로<br/>
            ‘기쁨은 나누면 배가 되고 슬픔은 나누면 반이 된다’는 사회 정서를 반영하였습니다.<br/>
            지역 주민들이 봉사자로 참여해 정서에 좋은 화초를 일정 기간 길러 도움이 필요한 사람에게<br/>
            기증하는 방식입니다. 봉사자와 수혜자 모두가 한마음으로 이어지자는 취지도 꾸려졌습니다.
        </>,
        descMobile: <>
            다다익선(多多益善) 프로젝트는 힐링·나눔·기부·환경을 주제로
            ‘기쁨은 나누면 배가 되고 슬픔은 나누면 반이 된다’는 사회 정서를 반영하였습니다.
            지역 주민들이 봉사자로 참여해 정서에 좋은 화초를 일정 기간 길러 도움이 필요한 사람에게
            기증하는 방식입니다. 봉사자와 수혜자 모두가 한마음으로 이어지자는 취지도 꾸려졌습니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/e2.jpg`,
            `${imgUrlOriginPath}/e3.jpg`,
            `${imgUrlOriginPath}/e4.jpg`,
            `${imgUrlOriginPath}/e5.jpg`,
            `${imgUrlOriginPath}/e6.jpg`,
        ]
    },
    {
        title: '어깨동무',
        mainImgUrl: `${imgUrlOriginPath}/f1.jpg`,
        desc: <>
            연탄 한 장, 김치 한 포기, 정다운 말 한마디, 따뜻한 눈길, 반가운 포옹, 밝은 미소.<br/>
            작은 것처럼 보일지라도 사람들을 위하는 진심이 담기면 그 어떤 것으로도 대신할 수 없는 가치가 됩니다. 각기 다 다른<br/>
            사람으로 태어나 다르게 자란 우리. 하지만 다른 모습을 가졌어도, 다른 생각과 다른 종교를 가졌어도 ‘사랑’이라는<br/>
            하나의 마음을 가진 우리는 이제 다름의 껍질을 벗고 함께 세상의 빛이 되고자 합니다.<br/>
            사랑은 사랑을 낳고, 그 사랑은 또 다른 사랑을 낳습니다. 봉사의 손길이 닿는 곳마다 열매가 맺힙니다.
        </>,
        descMobile: <>
            연탄 한 장, 김치 한 포기, 정다운 말 한마디, 따뜻한 눈길, 반가운 포옹, 밝은 미소.
            작은 것처럼 보일지라도 사람들을 위하는 진심이 담기면 그 어떤 것으로도 대신할 수 없는 가치가 됩니다. 각기 다 다른
            사람으로 태어나 다르게 자란 우리. 하지만 다른 모습을 가졌어도, 다른 생각과 다른 종교를 가졌어도 ‘사랑’이라는
            하나의 마음을 가진 우리는 이제 다름의 껍질을 벗고 함께 세상의 빛이 되고자 합니다.
            사랑은 사랑을 낳고, 그 사랑은 또 다른 사랑을 낳습니다. 봉사의 손길이 닿는 곳마다 열매가 맺힙니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/f2.jpg`,
            `${imgUrlOriginPath}/f3.jpg`,
            `${imgUrlOriginPath}/f4.jpg`,
            `${imgUrlOriginPath}/f5.jpg`,
            `${imgUrlOriginPath}/f6.jpg`,
        ]
    },
];

const TitleBox = ({title, desc}) => (
    <div className={cx('title_box')}>
        <h1>{title}</h1>
        <p>{desc}</p>
    </div>
);
const ImgBox = ({v, list}) => (
    <div className={cx(`img_box iv${v}`)}>
        {list.map((url, i) =>
            <LazyLoadImage
                src={url} effect="blur"
                wrapperClassName={putBigImgClass(v, i) ? 'big_img' : ''}
            />)}
    </div>
);
const putBigImgClass = (v, i) => (
    !isMobile ? i === v-1 : (v === 1 && i === 0) || (v === 2 && i === 2) || (v === 3 && i === 4)
);
const Sections = ({sectionVer, imgVer, cn, title, desc, imgUrlList, idx}) => (
    <section id={cn} className={cx(`sv${sectionVer+1}`)} key={idx}>
        <div className={cx('container')}>
            <TitleBox title={title} desc={desc} />
            <ImgBox v={imgVer+1} list={imgUrlList} />
        </div>
    </section>
);

const Neighbor = () => {
    return (
        <Layout>
            <PageTitle title={'이웃 사랑'}/>
            <div className={cx(!isMobile ? 'neighbor' : 'neighbor_mobile')}>
                <section className={cx('top')}>
                    <div className={cx('container')}>
                        {ContentsInfos.map((info, i) =>
                            <a className={cx('box main_box')} href={`#section${i + 1}`}>
                                <LazyLoadImage src={`${info.mainImgUrl}`} effect="blur" />
                                <div className={'title'}><p>{info.title}</p></div>
                            </a>
                        )}
                    </div>
                </section>
                {
                    ContentsInfos.map((info, i) =>
                        <Sections
                            sectionVer={i%2}
                            imgVer={i%3}
                            cn={`section${i+1}`}
                            title={info.title}
                            desc={!isMobile ? info.desc : info.descMobile}
                            imgUrlList={info.imgUrlList}
                            idx={i}
                        />
                    )
                }
            </div>
        </Layout>
    );
}

export default Neighbor;
