import { Link, useNavigate } from "react-router-dom";

import styles from "./popup.scss";
import classNames from "classnames";
import { useLayoutEffect, useState } from "react";

const cx = classNames.bind(styles);

const Popup = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/relation-center");
  };

  const [close, setClose] = useState(() => false);
  const [notToday, setNotToday] = useState(() => false);

  useLayoutEffect(() => {
    const isClose = sessionStorage.getItem("closePopup");
    const notTodayPopup = localStorage.getItem("notTodayPopup");
    const today = new Date();
    if (isClose || new Date(Number(notTodayPopup)).toLocaleDateString() === today.toLocaleDateString()) {
      setClose(true);
    } else {
      localStorage.removeItem("notTodayPopup");
    }
  }, []);

  const handleClose = () => {
    setClose(true);
    sessionStorage.setItem("closePopup", "true");
    if (notToday) {
      localStorage.setItem("notTodayPopup", new Date().getTime().toString());
    }
  };

  const handleNotToday = () => {
    setNotToday(!notToday);
  };

  return (
    <section className={cx("popup", close && "close")}>
      <figure onClick={handleClick}>
        <img src={process.env.REACT_APP_IMAGE_URL + "/hongbo/hongbo-0-main.jpg"} alt="" />
      </figure>
      <p className={cx("title")}>서울야고보지파 홍보관 예약</p>
      <div className={cx("discription")}>
        <p>서울야고보지파 홍보관 방문을 원하시면</p>
        <p>아래 예약 버튼을 클릭해 예약해주시기 바랍니다.</p>
        <p>시간별로 도슨트가 배정됩니다.</p>
      </div>
      <Link to="/relation-center">홍보관 예약 하러 가기</Link>
      <div className={cx("footer")}>
        <label>
          오늘 하루 보지 않기 <input checked={notToday} type="checkbox" onChange={handleNotToday} />
        </label>
        <button onClick={handleClose}>닫기</button>
      </div>
    </section>
  );
};

export default Popup;
