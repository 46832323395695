import React, {useState} from 'react';
import Layout from "../Layout/Layout";
import PageTitle from "../PageTitle/PageTitle";
import {AllNewsData} from './AllNewsData';
import {BranchNewsData} from "./BranchNewsData";

import styles from './Press.scss'
import classNames from "classnames";
import {isMobile} from "react-device-detect";
import Tabs from "../Tabs/Tabs";

const cx = classNames.bind(styles);

const Article = ({article}) => {
    return (
        <a href={article.link} target='_blank' rel="noreferrer">
            <div className={cx('article')}>
                <div className={cx('pic')} style={{backgroundImage: `url(${article.image})`}}/>
                <div className={cx('main_top')}>
                    <div className={cx('article_tit')}>
                        {article.title}
                    </div>
                    <div className={cx('article_cont')}>
                        <div className={cx('article_source')}>{article.source}</div>
                        <div className={cx('article_date')}>{article.date}</div>
                    </div>
                </div>
            </div>
        </a>
    );
}

const Press = () => {

    const [currentTab, setCurrentTab] = useState('allNews');

    const tabs = [
        {
            name: 'allNews',
            kor: '신천지 12지파'
        },
        {
            name: 'branchNews',
            kor: '서울야고보지파'
        }
    ]

    return (
        <Layout>
            <PageTitle title='언론보도'/>
            <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab}/>

            <div className={cx(!isMobile ? 'Press' : 'Press_mobile')}>
                <div className={cx('item_wrap')}>
                    {
                        currentTab === 'allNews' &&
                        AllNewsData.map((article, i) => <Article article={article} key={i}/>)
                    }
                    {
                        currentTab === 'branchNews' &&
                        BranchNewsData.map((article, i) => <Article article={article} key={i}/>)
                    }
                </div>
            </div>
        </Layout>
    );
}

export default Press;
