import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import RelationCenterNav from "../../components/RelationCenterHistory/RelationCenterHistoryContent/RelationCenterNav";

import styles from "./HistoryContent.scss";
import classNames from "classnames";
import { imageURL } from "../../libs/image";
const cx = classNames.bind(styles);

const CONTENTS = {
  intro: {
    background: imageURL + "/hongbo-0-main.jpg",
    main: imageURL + "/hongbo-0-main.jpg",
    subtitle: "성경에 약속된 대로 세워진 교회 신천지예수교회",
    discription: `교회의 공식 명칭인 신천지예수교증거장막성전은 하나님과 예수님께서 함께하시는 거룩한 성전이자 계시록이 성취된 사건들을 증거하는 곳이라는 의미입니다. 

    신천지예수교회는 각 국가와 지역별로 12지파에 소속되어 있는 가운데 서울야고보지파에서 운영하는 홍보관을 소개합니다. 
    `,
  },
  media: {
    background: imageURL + "/hongbo-1-main.jpg",
    main: imageURL + "/hongbo-1.jpg",
    sub: imageURL + "/hongbo-1-main.jpg",
    subtitle: "6천년 하나님의 역사와 신천지의 실상을 소개한다",
  },
  shincheonji: {
    background: imageURL + "/hongbo-3-main.jpg",
    main: imageURL + "/hongbo-3-main.jpg",
    subtitle: "신천지 발전사",
    discription: `신천지예수교회는 1984년 3월 14일을 창립일로 공식 선포했습니다. 당시 성도 수는 100여 명에 불과했지만 10년 만에 20배에 가까운 성장을 이루었습니다. 현재는 국내 78개 교회와 해외 69개국 60개 교회에서 36만여 명 성도가 신앙하고 있습니다.`,
  },
  books: {
    background: imageURL + "/hongbo-2-main.jpg",
    main: imageURL + "/hongbo-2-main.jpg",
    subtitle: "신천지 도서",
    discription: "신천지예수교회에서 발간되 주요 서적을 소개하며, 신천지 성도들은 총회장님이 저술하신 도서들을 토대로 성경에 입각한 신앙생활을 하고 있습니다.",
  },
  "twelve-tribe": {
    background: imageURL + "/hongbo-4-main.jpg",
    main: imageURL + "/hongbo-4-main.jpg",
    subtitle: "신천지 12지파와 총회",
    discription: `신천지예수교회에는 성경의 약속대로 창조된 12지파와 총회가 있습니다.
    12지파는 예수님께서 약속하신 대로 열두 제자 이름으로 되어 있습니다.`,
  },
  eduzion: {
    background: imageURL + "/hongbo-5-main.jpg",
    main: imageURL + "/hongbo-5-main.jpg",
    subtitle: "시온기독교선교센터",
    discription: `시온기독교선교센터는 하나님의 약속으로 세워진 신천지예수교회의 신학 교육 전문 기관입니다. 남녀노소, 학력, 지위와 상관없이 성경 말씀을 배우고 싶은 사람은 누구나 무료로 수강할 수 있으며, 2023년 10만 8084명의 114기 수료생을 배출하였습니다. 

    전세계 558개소(국내 332개소, 해외 226개소)에서 매달 개강하고 있으며, 신천지 온라인 세미나(유투브)를 통해 시온기독교선교센터와 동일한 교육과정을 제공하고 있습니다. (2023년 1월 기준)
    
    또한, 신천지예수교회로 계시말씀을 확인한 전 세계 목회자들이 사역하는 교회의 간판을 바꾸며 신천지예수교회의 소속이 되고 있습니다.
    
    이들은 오직 계시말씀으로 양육받고, 성도들을 양육하겠다는 일념하나로 신천지예수교회와 계시복음 교류를 이어 가고 있습니다`,
  },
  earth: {
    background: imageURL + "/hongbo-6-main.jpg",
    main: imageURL + "/hongbo-6-main.jpg",
    subtitle: "신천지 MOU / 신천지 자원봉사",
    discription: `현재 전 세계 목회자와 신학생들이 신천지 계시신학을 확인하고 깨달음으로 신천지예수교회와 말씀교류 MOU를 체결하고 후속 교육을 받기로 약속한 교회가 국내 409개, 해외 79개국 9,018개에 이르고 있습니다. 

    신천지자원봉사단은 보훈, 장애인, 공익, 문화 등 범사회적분야에서 종교, 인종, 국경을 초월하여 전 세계적 사회 공헌 사업을 펼치고 있습니다. 작년 대비 수혜자 2배 이상 증가하는 등 더욱 활발하게 봉사를 펼쳤습니다. 신천지예수교회의 봉사 활동으로 예수님의 사랑을 지역 곳곳에 따뜻함과 희망으로 전하고 있습니다.`,
  },
};

const HistoryContent = () => {
  const { pathname } = useLocation();

  const nowContent = Object.entries(CONTENTS).find(([url]) => pathname.includes(url))[1];

  const [mFirstLoading, setFirstLoading] = useState(() => false);
  useEffect(() => {
    setFirstLoading(true);
  }, []);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <main className={cx("history-content")} ref={contentRef}>
      <figure className={`${cx("background-image")} ${mFirstLoading ? cx("active") : ""}`}>
        <img src={nowContent.background} alt="backgroundImage" />
      </figure>
      <RelationCenterNav />
      <div className={cx("content")}>
        <article className={cx("article")}>
          <figure className="main-img">
            <img src={nowContent.main} alt="descriptionImage" />
          </figure>
        </article>
        <article className={cx("article")}>
          {nowContent.sub ? (
            <figure className="sub-img">
              <img src={nowContent.sub} alt="descriptionImage" />
            </figure>
          ) : (
            <></>
          )}
          {nowContent.subtitle ? <h3>{nowContent.subtitle}</h3> : <></>}
          {nowContent.discription ? <p>{nowContent.discription}</p> : <></>}
        </article>
        <img className="logo" src="https://d3fc8mvhyp5uqo.cloudfront.net/images/logo_white.png" alt="" />
      </div>
    </main>
  );
};

export default HistoryContent;
