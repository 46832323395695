import React from 'react';
import Layout from "../Layout/Layout";
import {isMobile} from "react-device-detect";
import PageTitle from "../PageTitle/PageTitle";

import styles from './Greetings.scss'
import classNames from "classnames";

const cx = classNames.bind(styles);

const Greetings = () => {

    const HeadChief = process.env.REACT_APP_IMAGE_URL + '/greetings/head_chief.png';
    const BranchChief = process.env.REACT_APP_IMAGE_URL + '/greetings/branch_chief.png';

    return (
        <Layout>
            <PageTitle title={'인사말씀'}/>

            <div className={cx(!isMobile ? 'Greetings' : 'Greetings_mobile')}>
                <div className={cx('section1')}>
                    <div className={cx('wrapper')}>
                        <div className={cx('left_side')}>
                            <img src={HeadChief} alt={''}/>
                            <div className={cx('box1')}/>
                        </div>

                        <div className={cx('right_side')}>
                            <div className={cx('right_title')}>
                                천국을 소망하는<br/>
                                전 세계 신앙인들에게 전합니다.<br/>
                                <br/>
                            </div>
                            <div className={cx('right_content')}>
                                안녕하십니까?<br/>
                                저는 신천지예수교회 대표 이만희 입니다.<br/>
                                저는 요한계시록 1장에서 22장까지 전장 사건을 다 보고 들었습니다.<br/>
                                모세가 장막을 지을 때 하늘의 것을 다 본 것같이 말입니다.<br/>
                                <br/>
                                예수님은 신약 계시록 곳곳에 택한 한 사람을 알리셨습니다.<br/>
                                예고 없이 보내신 것이 아닙니다.<br/>
                                예수님의 예언을 깨달아야 하며, 어린아이같이 낮아져야 합니다(마 18장).<br/>
                                예수님 초림 때 두 가지 씨가 같은 한 밭에 뿌려졌고,<br/>
                                재림 때에는 하나님의 씨로 난 자들만 추수해 가고<br/>
                                가라지 곧 마귀 씨로 난 자들은 그 밭에 남겨 두었습니다.(마 13:24-30, 38-39 참고)<br/>
                                <br/>
                                추수된 자들과 추수되지 못한 자들, 성도님은 이 둘 중 어느 쪽입니까?<br/>
                                이것은 천국과 지옥의 문제입니다.<br/>
                                이 일들을 깨달은 자는 하나님의 씨와 영으로 다시 나게 됩니다.<br/>
                                <br/>
                                우리가 성경으로 신앙을 시작하였으니, 성경대로 신앙을 해야 소망을 이룰 것입니다.<br/>
                                천국이 정말 가까이 왔습니다. 하나님과 예수님과 천사들이 기다리고 계십니다.<br/>
                                <br/>
                            </div>
                            <div className={cx('right_name')}>
                                신천지예수교 증거장막성전<br/>
                                총 회 장  이 만 희
                            </div>
                        </div>
                    </div>
                </div>

                <div className={cx('section2')}>
                    <div className={cx('wrapper', 'branch_chief')}>
                        <div className={cx('left_side')}>
                            <img src={BranchChief} alt={''}/>
                            <div className={cx('box2')}/>
                        </div>

                        <div className={cx('right_side')}>
                            <div className={cx('right_title')}>
                                사랑하는 기독교 성도 여러분, 반갑습니다!<br/>
                                <br/>
                            </div>
                            {
                                !isMobile ?
                                    <div className={cx('right_content')}>
                                        방문하신 이곳은 신약의 약속의 목자로부터 계시 말씀으로 인 맞아 창조된<br/>
                                        12지파(계 7장) 가운데 예수님의 제자 야고보의 이름으로 세워진<br/>
                                        서울야고보지파입니다.<br/>
                                        <br/>
                                        서울야고보지파는 '진리의 성읍, 공의공도의 나라, 최선의 성군' 이라는<br/>
                                        신천지의 신조 아래 새 언약을 지키며 진리의 사랑을 전하고 있습니다.<br/>
                                        또한 십자가의 사랑을 실천하신 예수님의 뜻에 따라, 빛과 비와 공기같이<br/>
                                        사랑의 실체가 되어 빛과 소금의 역할을 다하고자 노력하고 있습니다.<br/>
                                        <br/>
                                        예수님께서 우리를 사랑하신 것같이 우리도 예수님께서 주신 사랑의 계명을 지켜<br/>
                                        서로 사랑합시다. 하루속히 세상 나라가 하나님의 나라가 되도록 함께 노력합시다.<br/>
                                        그리고 하나님 안에서, 진리 안에서 하나가 됩시다.<br/>
                                        <br/>
                                        신앙인이라면 반드시 알아야 할 것들이 있습니다.<br/>
                                        나는 성경이 말하는 누구인가? 나는 누구의 씨로 났는가?<br/>
                                        나는 약속의 말씀대로 창조되었는가? 이 질문의 답을 알고 싶은 분들,<br/>
                                        진리의 계시 말씀과 진실한 만남을 원하는 분들을 신천지예수교회에 초대합니다.<br/>
                                        <br/>
                                        아버지 하나님과 예수 그리스도의 은혜와 평강이<br/>
                                        모든 분들께 영원히 함께하시기를 기원합니다. 감사합니다.<br/>
                                        <br/>
                                    </div>
                                    :
                                    <div className={cx('right_content')}>
                                        방문하신 이곳은 신약의 약속의 목자로부터 계시 말씀으로 인 맞아 창조된
                                        12지파(계 7장) 가운데 예수님의 제자 야고보의 이름으로 세워진
                                        서울야고보지파입니다.<br/>
                                        <br/>
                                        서울야고보지파는 '진리의 성읍, 공의공도의 나라, 최선의 성군' 이라는
                                        신천지의 신조 아래 새 언약을 지키며 진리의 사랑을 전하고 있습니다.
                                        또한 십자가의 사랑을 실천하신 예수님의 뜻에 따라, 빛과 비와 공기같이
                                        사랑의 실체가 되어 빛과 소금의 역할을 다하고자 노력하고 있습니다.<br/>
                                        <br/>
                                        예수님께서 우리를 사랑하신 것같이 우리도 예수님께서 주신 사랑의 계명을 지켜
                                        서로 사랑합시다. 하루속히 세상 나라가 하나님의 나라가 되도록 함께 노력합시다.
                                        그리고 하나님 안에서, 진리 안에서 하나가 됩시다.<br/>
                                        <br/>
                                        신앙인이라면 반드시 알아야 할 것들이 있습니다.
                                        나는 성경이 말하는 누구인가? 나는 누구의 씨로 났는가?
                                        나는 약속의 말씀대로 창조되었는가? 이 질문의 답을 알고 싶은 분들,
                                        진리의 계시 말씀과 진실한 만남을 원하는 분들을 신천지예수교회에 초대합니다.<br/>
                                        <br/>
                                        아버지 하나님과 예수 그리스도의 은혜와 평강이
                                        모든 분들께 영원히 함께하시기를 기원합니다. 감사합니다.<br/>
                                        <br/>
                                    </div>
                            }
                            <div className={cx('right_name')}>
                                신천지예수교 증거장막성전<br/>
                                서울야고보 지파장 신 규 수
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Greetings;
