import React from 'react';
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import styles from './SpotlightItem.scss';
import classNames from "classnames";

const cx = classNames.bind(styles);

const SpotlightItem = ({ imgSrc, title, content, link, tag}) => {

    const navigate = useNavigate();

    return (
        <div className={cx(!isMobile ? 'SpotlightItem' : 'SpotlightItem_mobile')} onClick={() => navigate(link)}>
            <div className={cx('pic')} style={{backgroundImage: `url(${imgSrc})`}}/>
            <div className={cx('main_top')}>
                <div className={cx('spot_tit')}>
                    {
                        tag &&
                        <span className={cx('tag')}>[{tag}]</span>
                    }
                    {title}
                </div>
                <div className={cx('spot_cont')}>{content}</div>
            </div>
        </div>
    );
}

export default SpotlightItem;
