import React from 'react';
import {isMobile} from "react-device-detect";
import Layout from "../Layout/Layout";
import PageTitle from "../PageTitle/PageTitle";
import {HistoryData} from "./HistoryData";

import styles from './History.scss'
import classNames from "classnames";

const cx = classNames.bind(styles);

const History = () => {
    return (
        <Layout>

            <PageTitle title='연혁'/>

            <div className={cx(!isMobile ? 'History' : 'History_mobile')}>
                <section className={cx('top')}>
                    {
                        !isMobile ?
                            // PC는 사진-텍스트 순
                            <>
                                <div className={cx('picture')}>
                                    {HistoryData.topPicture.map((element, i) =>
                                        <div className={cx('pic_wrap')}>
                                            <img src={element.src} alt={''}/>
                                            <span>{element.desc}</span>
                                        </div>
                                    )}
                                </div>
                                <div className={cx('text')}>
                                    <div className={cx('title')}>서울야고보지파 설립 이전</div>
                                    <table>
                                        <tbody>
                                        {HistoryData.top.map((element, i) =>
                                            <tr key={i}>
                                                <td className={cx('year')}>{element.year}</td>
                                                <td>{element.content}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            :
                            // 모바일은 텍스트-사진 순
                            <>
                                <div className={cx('text')}>
                                    <div className={cx('title')}>서울야고보지파 설립 이전</div>
                                    <table>
                                        <tbody>
                                        {HistoryData.top.map((element, i) =>
                                            <tr key={i}>
                                                <td className={cx('year')}>{element.year}</td>
                                                <td>{element.content}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className={cx('picture')}>
                                    {HistoryData.topPicture.map((element, i) =>
                                        <div className={cx('pic_wrap')}>
                                            <img src={element.src} alt={''}/>
                                            <span>{element.desc}</span>
                                        </div>
                                    )}
                                </div>
                            </>
                    }
                </section>

                <section className={cx('middle')}>
                    {/* PC, 모바일 동일하게 텍스트-사진 순 */}
                    <div className={cx('text')}>
                        <div className={cx('title')}>성전</div>
                        <table>
                            <tbody>
                            {HistoryData.middle.map((element, i) =>
                                <tr key={i}>
                                    <td className={cx('year')}>{element.year}</td>
                                    <td>{element.content}</td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className={cx('picture')}>
                        {HistoryData.middlePicture.map((element, i) =>
                            <div className={cx('pic_wrap')}>
                                <img src={element.src} alt={''}/>
                                <span>{element.desc}</span>
                            </div>
                        )}
                    </div>
                </section>

                <section className={cx('bottom')}>
                    {
                        !isMobile ?
                            // PC는 사진-텍스트 순
                            <>
                                <div className={cx('picture')}>
                                    {HistoryData.bottomPicture.map((element, i) =>
                                        <div className={cx('pic_wrap')}>
                                            <img src={element.src} alt={''}/>
                                            <span>{element.desc}</span>
                                        </div>
                                    )}
                                </div>
                                <div className={cx('text')}>
                                    <div className={cx('title')}>수료식</div>
                                    <table>
                                        <tbody>
                                        {HistoryData.bottom.map((element, i) =>
                                            <tr key={i}>
                                                <td className={cx('year')}>{element.year}</td>
                                                <td>{element.content}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                            :
                            // 모바일은 텍스트-사진 순
                            <>
                                <div className={cx('text')}>
                                    <div className={cx('title')}>수료식</div>
                                    <table>
                                        <tbody>
                                        {HistoryData.bottom.map((element, i) =>
                                            <tr key={i}>
                                                <td className={cx('year')}>{element.year}</td>
                                                <td>{element.content}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className={cx('picture')}>
                                    {HistoryData.bottomPicture.map((element, i) =>
                                        <div className={cx('pic_wrap')}>
                                            <img src={element.src} alt={''}/>
                                            <span>{element.desc}</span>
                                        </div>
                                    )}
                                </div>
                            </>
                    }

                </section>
            </div>
        </Layout>
    );
}

export default History;
