import React, {useState, useEffect} from "react";
import Layout from "../Layout/Layout";
import {isMobile} from "react-device-detect";
import styles from './BlueSkyTv.scss';
import classNames from "classnames";
const cx = classNames.bind(styles);

const contentsBox = (size, img, url, i) => (
    <div className={cx(`${size}_box`)}
         style={{backgroundImage:`url(${img})`}}
         onClick={() => window.open(url)}
         key={i}
    />
);

const BlueSkyTv = () => {
    // const [mobile, setMobile] = useState(false);
    const [contentsInfo, setContentsInfo] = useState([]);
    useEffect(() => {
        setContentsInfo([]);

        let seq, bigNum;
        if (!isMobile) {
            seq = [1,2,3,4,5,6,7,8,9,10,11,12];
            bigNum = [1, 7, 11];
        } else {
            seq = [1,2,3,5,4,6,7,8,9,10,12,11];
            bigNum = [1, 5, 7, 11];
        }

        const urlList = [
            'https://youtu.be/f5Gm9g0VHTk',
            'https://youtu.be/MrWtwwJ6tOE',
            'https://youtu.be/OcXz8_9HOK4',
            'https://youtu.be/eMe5J7ovP8A',
            'https://youtu.be/xDhGW4NGvoU',
            'https://youtu.be/5CxAdz2wgg4',
            'https://youtu.be/8vgik3veDvE',
            'https://youtu.be/QY77Hwgv7oE',
            'https://youtu.be/m2Dt32_doxM',
            'https://youtu.be/p7lwZpBKDfs',
            'https://youtu.be/hYQsGubmvNk',
            'https://youtu.be/9fgkj5Bff5s'
        ]

        seq.forEach((s) => {
            setContentsInfo((contentsInfo) => { return [...contentsInfo, {
                'img': `${process.env.REACT_APP_IMAGE_URL}/blue_sky_tv/${s > 9 ? s:'0'+s}.jpg`,
                'size': bigNum.includes(s) ? 'big' : 'small',
                'url': urlList[s-1]
            }]});
        });
    }, []);

    return (
        <Layout>
            <div className={cx(!isMobile ? 'blue_sky_tv' : 'blue_sky_tv_mobile')} style={{backgroundImage:`url(${`${process.env.REACT_APP_IMAGE_URL}/back.jpg`})`}}>
                <section className={cx('top')}>
                    <div>
                        <span>서울야고보지파 공식채널</span>
                        <img src={`${process.env.REACT_APP_IMAGE_URL}/blue_sky_tv/logo.webp`} alt={''}/>
                    </div>
                </section>

                <section className={cx('middle')}>
                    <div className={cx('bold')}>서울야고보지파 공식 채널 파란하늘 TV는</div><br/>
                    {
                        !isMobile ? <>
                                <p>드로잉 바이블, 뮤직, 현문현답, 풍경 성구, 성구 캘리그라피, 성구 낭독 영상 등</p>
                                <p>신앙인들의 신앙 성장에 도움을 줄 수 있는  다양한 콘텐츠가 지속적으로 게시되는 유튜브 채널입니다.</p>
                                <p>신천지 성도 외 일반인을 포함하여 평균 천여 명 이상이 파란하늘 TV의 영상콘텐츠를 시청하고 있습니다.</p>
                            </> :
                            <p>
                                드로잉 바이블, 뮤직, 현문현답, 풍경 성구, 성구 캘리그라피, 성구 낭독 영상 등 신앙인들의 신앙 성장에 도움을 줄 수 있는  다양한 콘텐츠가 지속적으로 게시되는 유튜브 채널입니다.
                                신천지 성도 외 일반인을 포함하여 평균 천여 명 이상이 파란하늘 TV의 영상콘텐츠를 시청하고 있습니다.
                            </p>
                    }
                </section>

                <section className={cx('body')}>
                    {contentsInfo.map((info, i) => contentsBox(info.size, info.img, info.url, i))}
                </section>

                <section className={cx('bottom')}>
                    <span onClick={() => window.open('https://www.youtube.com/c/파란하늘TV')}>
                        YOUTUBE https://www.youtube.com/c/파란하늘TV
                    </span>
                </section>
            </div>
        </Layout>
    );
}

export default BlueSkyTv;
