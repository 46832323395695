import React, { Fragment } from "react";
import { useLocation } from "react-router-dom";
import { relationNavigation } from "../../../relationNavigation";
import ListNavigationItem from "../../RelationCenterMain/ListNavigation";
import Icon from "../../Icon";
import RelationCenterFixedTitle from "./RelationCenterFixedTitle";

import styles from "./RelationCenterNav.scss";
import classNames from "classnames";
const cx = classNames.bind(styles);

const RelationCenterNav = () => {
  const { pathname } = useLocation();

  const nowPage = Object.entries(relationNavigation["history"]).find(([url]) => pathname.includes(url));

  const nowPageTitle = (typeof nowPage?.[1] === "object" && nowPage?.[1].title) || "";

  return (
    <>
      <ul className={cx("history-nav")}>
        {Object.entries(relationNavigation["history"]).map(([url, value]) => {
          if (url === "component" || url === "title") {
            return <Fragment key={url} />;
          }
          console.log(url);
          const isActive = pathname.includes(url);
          return (
            <ListNavigationItem key={url} href={`/relation-center/history/${url}`}>
              <Icon icon="menu" style={{ opacity: isActive ? 1 : 0.5 }} />
              <p style={{ fontFamily: "Noto Serif KR", opacity: isActive ? 1 : 0.5 }}>{value.title}</p>
            </ListNavigationItem>
          );
        })}
      </ul>
      <RelationCenterFixedTitle title={nowPageTitle} />
    </>
  );
};

export default RelationCenterNav;
