import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useParams } from "react-router-dom";
import styles from "./Church.scss";
import classNames from "classnames";
import PageTitle from "../PageTitle/PageTitle";
import Layout from "../Layout/Layout";
const cx = classNames.bind(styles);

const imgUrlOriginPath = `${process.env.REACT_APP_IMAGE_URL}/church`;
const churchInfo = {
  seoul: {
    name: "서울",
    desc: (
      <div className={cx("desc")}>
        <strong>서울야고보지파 본부 교회인 서울교회는</strong>
        <strong>대한민국 수도 서울 중심부에 위치하고 있습니다.</strong>
        <br />
        하나님의 사랑과 평화로 세상의 빛이 되는 참된 신앙인으로서
        <br />
        이웃 사랑을 실천하고 다양한 문화·예술 활동으로
        <br />
        지역 사회 발전에 기여하고 있습니다.
        <br />
        <br />
        하나님 안에서 우리 모두 하나 되기를 원합니다.
        <br />
        <br />
        <strong>신천지예수교 증거장막성전</strong>
        <strong>서울야고보지파 지파장 신규수</strong>
        <br />
      </div>
    ),
    descMobile: (
      <div className={cx("desc")}>
        <strong>서울야고보지파의 본부 교회인 서울교회는</strong>
        <br />
        <strong>대한민국의 수도 서울의 중심부에 위치하고 있습니다.</strong>
        <br />
        <br />
        하나님의 사랑과 평화로 세상의 빛이 되는 참된 신앙인으로서 이웃 사랑을
        실천하고 다양한 문화·예술 활동으로 지역 사회 발전에 기여하고 있습니다.
        <br />
        <br />
        하나님 안에서 우리 모두 하나 되기를 원합니다.
        <br />
        <br />
        <strong>신천지예수교 증거장막성전</strong>
        <br />
        <strong>서울야고보지파 지파장 신규수</strong>
        <br />
      </div>
    ),
  },
  pocheon: {
    name: "포천",
    desc: (
      <div className={cx("desc")}>
        <strong>사랑하는 기독교 성도여러분, 진심으로 환영합니다.</strong>
        <br />
        방문해 주신 포천교회는 신약의 약속의 목자를 통해서 계시 말씀으로 인 맞아
        <br />
        창조된 신천지 12지파 가운데 예수님의 제자 야고보의 이름으로 세워진
        <br />
        서울야고보지파의 지교회입니다.
        <br />
        <br />
        포천이란 지명은 물이 외부로 흘러나간다고 하여 안을 포(抱), 내 천(川)으로
        <br />
        이름 하였습니다. 그 명칭에 걸맞게 포천교회는 성도들에게 물과 같은
        하나님의
        <br />
        생명의 말씀을(신32:2) 가르치고, 지역사회에 빛이 되기 위해 노력하고
        있습니다.
        <br />
        <br />
        네 이웃을 네 몸과 같이 사랑하라(마22:39)는 성경의 가르침대로
        <br />
        이웃사랑을 몸소 실천하고 있으며, 이 땅의 하나님의 나라를 이루기 위해서
        <br />
        영혼들을 살리는 일을 하고 있는 진실과 화평이 가득한 교회입니다.
        <br />
        <br />
        앞으로도 우리 모두가 진리 안에서 서로 사랑하고, 활발한 교류를 통해서
        <br />
        예수님의 아름다운 덕을 선전하고, 천국에 함께 하기를 진심으로
        <br />
        소망합니다.
        <br />
        <br />
        <strong>신천지예수교 증거장막성전</strong>
        <strong>서울야고보지파 포천교회 담임 윤광웅</strong>
      </div>
    ),
    descMobile: (
      <div className={cx("desc")}>
        <strong>사랑하는 기독교 성도여러분, 진심으로 환영합니다.</strong>
        <br />
        <br />
        방문해 주신 포천교회는 신약의 약속의 목자를 통해서 계시 말씀으로 인 맞아
        창조된 신천지 12지파 가운데 예수님의 제자 야고보의 이름으로 세워진
        서울야고보지파의 지교회입니다.
        <br />
        <br />
        포천이란 지명은 물이 외부로 흘러나간다고 하여 안을 포(抱), 내 천(川)으로
        이름 하였습니다. 그 명칭에 걸맞게 포천교회는 성도들에게 물과 같은
        하나님의 생명의 말씀을(신32:2) 가르치고, 지역사회에 빛이 되기 위해
        노력하고 있습니다.
        <br />
        <br />
        네 이웃을 네 몸과 같이 사랑하라(마22:39)는 성경의 가르침대로 이웃사랑을
        몸소 실천하고 있으며, 이 땅의 하나님의 나라를 이루기 위해서 영혼들을
        살리는 일을 하고 있는 진실과 화평이 가득한 교회입니다.
        <br />
        <br />
        앞으로도 우리 모두가 진리 안에서 서로 사랑하고, 활발한 교류를 통해서
        예수님의 아름다운 덕을 선전하고, 천국에 함께 하기를 진심으로 소망합니다.
        <br />
        <br />
        <strong>신천지예수교 증거장막성전</strong>
        <br />
        <strong>서울야고보지파 포천교회 담임 윤광웅</strong>
      </div>
    ),
  },
  guri: {
    name: "구리",
    desc: (
      <div className={cx("desc")}>
        <strong>
          하나님과 예수님의 은혜와 사랑이 방문해 주신 모든 분들에게
        </strong>
        <strong>함께 하시길 바랍니다.</strong>
        <br />
        안녕하세요~ 반갑습니다.
        <br />
        이곳 구리교회는 예수님의 새 언약 계시록의 말씀과 같이 오늘날 창조된
        <br />
        새 하늘 새 땅 12지파 중 서울야고보지파 산하 지교회 중 하나입니다.
        <br />
        <br />
        성경이 약속한 목자를 중심으로 성전과 성도들이 성경대로 창조되어
        <br />
        성경의 실체들이 되었습니다. 온 세상에 하나님과 예수님의 구원의 은혜의
        복음을
        <br />
        증거하며 지역 사회에 주님의 사랑을 실천하고 있습니다.
        <br />
        <br />
        앞으로 여러분들과 주님 안에서 많은 교류와 교제를 통해
        <br />
        하나님의 은혜를 더욱 나누기를 기대합니다. 감사합니다.
        <br />
        <br />
        <strong>신천지예수교회 증거장막성전</strong>
        <strong>서울야고보지파 구리교회 담임 강창훈</strong>
        <br />
      </div>
    ),
    descMobile: (
      <div className={cx("desc")}>
        <strong>
          하나님과 예수님의 은혜와 사랑이 방문해 주신 모든 분들에게 함께 하시길
          바랍니다.
        </strong>
        <br />
        <br />
        안녕하세요~ 반갑습니다.
        <br />
        이곳 구리교회는 예수님의 새 언약 계시록의 말씀과 같이 오늘날 창조된
        새하늘 새땅 12지파 중 서울야고보지파 산하 지교회 중 하나입니다.
        <br />
        <br />
        성경이 약속한 목자를 중심으로 성전과 성도들이 성경대로 창조되어 성경의
        실체들이 되었습니다. 온 세상에 하나님과 예수님의 구원의 은혜의 복음을
        증거하며 지역 사회에 주님의 사랑을 실천하고 있습니다.
        <br />
        <br />
        앞으로 여러분들과 주님 안에서 많은 교류와 교제를 통해 하나님의 은혜를
        더욱 나누기를 기대합니다. 감사합니다.
        <br />
        <br />
        <strong>신천지예수교회 증거장막성전</strong>
        <br />
        <strong>서울야고보지파 구리교회 담임 강창훈</strong>
        <br />
      </div>
    ),
  },
  dongdaemun: {
    name: "동대문",
    desc: (
      <div className={cx("desc")}>
        <strong>하나님의 사랑으로 환영합니다.</strong>
        <br />
        동대문 교회는 새 언약 계시록대로 창조된 <br />
        신천지 12지파 중 예수님의 제자 야고보의 이름으로 세워진
        <br />
        서울야고보지파 소속의 지교회입니다.
        <br />
        <br />
        동대문교회는 예수님께서 보여주신 십자가의 사랑을 마음에 담고,
        <br />
        세상에 빛과 소금과 같은 역할을 다하기 위하여 봉사하고 있습니다.
        <br />
        <br />
        하나님과의 진실한 만남을 원하신다면
        <br />
        신천지예수교회 동대문교회로 초대합니다.
        <br />
        <br />
        오늘도 아버지 하나님과 예수 그리스도의 은혜와 평강이
        <br />
        모든 분들께 영원히 함께하시기를 기원합니다.
        <br />
        감사합니다.
        <br />
        <br />
        <strong>신천지예수교 증거장막성전</strong>
        <strong>서울야고보지파 동대문교회 담임 유영주</strong>
        <br />
      </div>
    ),
    descMobile: (
      <div className={cx("desc")}>
        <strong>하나님의 사랑으로 환영합니다.</strong>
        <br />
        동대문 교회는 새 언약 계시록대로 창조된 <br />
        신천지 12지파 중 예수님의 제자 야고보의 이름으로 세워진
        <br />
        서울야고보지파 소속의 지교회입니다.
        <br />
        <br />
        동대문교회는 예수님께서 보여주신 <br />
        십자가의 사랑을 마음에 담고,
        <br />
        세상에 빛과 소금과 같은 역할을 <br />
        다하기 위하여 봉사하고 있습니다.
        <br />
        <br />
        하나님과의 진실한 만남을 원하신다면
        <br />
        신천지예수교회 동대문교회로 초대합니다.
        <br />
        <br />
        오늘도 아버지 하나님과 예수 그리스도의 은혜와 평강이
        <br />
        모든 분들께 영원히 함께하시기를 기원합니다.
        <br />
        감사합니다.
        <br />
        <br />
        <strong>신천지예수교 증거장막성전</strong>
        <strong>서울야고보지파 동대문교회 담임 유영주</strong>
        <br />
      </div>
    ),
  },
  uijung: {
    name: "의정부",
    desc: (
      <div className={cx("desc")}>
        <strong>주 안에서 환영합니다.</strong>
        <strong>진리의 말씀으로 하나가 됩시다!</strong>
        <br />
        우리 의정부교회는 계시 말씀으로 창조된 신천지 12지파 가운데 예수님의 제자
        <br />
        야고보의 이름으로 세워진 서울야고보지파의 지교회입니다.
        <br />
        <br />
        신약의 약속의 목자께서 '의정부(議政府)'에 의의 빛을
        <br />
        비추는 명에 의해 세워진 교회로 하나님과 예수님의 뜻에 따라, 진리를
        전하고
        <br />
        선한 행실로 봉사하며 하늘에서 값없이 주는 빛과 비와 공기같이
        <br />
        지역사회에 사랑의 실체가 되기 위해 노력하고 있습니다.
        <br />
        <br />
        여러분과 함께 계시의 말씀에 대해 나누고 하나님과 진리 안에서
        <br />
        하나가 되길 원합니다.
        <br />
        하나님과 예수님의 은혜와 사랑이 여러분과 함께하기를 기원합니다.
        <br />
        <br />
        <strong>신천지예수교 증거장막성전</strong>
        <strong>서울야고보지파 의정부교회 담임 김우휘</strong>
        <br />
      </div>
    ),
    descMobile: (
      <div className={cx("desc")}>
        <strong>주 안에서 환영합니다.</strong>
        <br />
        <strong>진리의 말씀으로 하나가 됩시다!</strong>
        <br />
        <br />
        우리 의정부교회는 계시 말씀으로 창조된 신천지 12지파 가운데 예수님의 제자
        야고보의 이름으로 세워진 서울야고보지파의 지교회입니다.
        <br />
        <br />
        신약의 약속의 목자께서 '의정부(議政府)'에 의의 빛을 비추는 명에 의해
        세워진 교회로 하나님과 예수님의 뜻에 따라, 진리를 전하고 선한 행실로
        봉사하며 하늘에서 값없이 주는 빛과 비와 공기같이 지역사회에 사랑의
        실체가 되기 위해 노력하고 있습니다.
        <br />
        <br />
        여러분과 함께 계시의 말씀에 대해 나누고 하나님과 진리 안에서 하나가 되길
        원합니다. 하나님과 예수님의 은혜와 사랑이 여러분과 함께하기를
        기원합니다.
        <br />
        <br />
        <strong>신천지예수교 증거장막성전</strong>
        <br />
        <strong>서울야고보지파 의정부교회 담임 김우휘</strong>
        <br />
      </div>
    ),
  },
};

const Church = () => {
  const { churchName } = useParams();

  const [churchEngName, setChurchEngName] = useState(null);
  const [thisChurch, setThisChurch] = useState({});
  useEffect(() => {
    setChurchEngName(() => churchName);
    setThisChurch(churchInfo[churchName]);
  }, [churchName]);

  return (
    <Layout>
      <PageTitle title={"교회소개"} />

      <div className={cx(!isMobile ? "church" : "church_mobile")}>
        <section className={cx("top")}>
          <img
            className={cx("church_icon")}
            src={`${imgUrlOriginPath}/church_icon.png`}
            alt={""}
          />
          <h1>{`${thisChurch.name}교회`}</h1>
        </section>

        <section className={cx("middle")}>
          {!isMobile ? (
            <>
              <div className={cx("chief")}>
                <LazyLoadImage
                  src={`${imgUrlOriginPath}/${churchEngName}_chief.jpg`}
                  effect="blur"
                />
              </div>
              {thisChurch.desc}
            </>
          ) : (
            <>
              <div className={cx("chief")}>
                <LazyLoadImage
                  src={`${imgUrlOriginPath}/${churchEngName}_chief.jpg`}
                  effect="blur"
                />
              </div>
              {thisChurch.descMobile}
            </>
          )}
        </section>

        <section className={cx("church_imgs")}>
          <LazyLoadImage
            src={`/seoul_image_1.jpg`}
            effect="blur"
          />
          <LazyLoadImage
            src={`/seoul_image_2.jpg`}
            effect="blur"
          />
          <LazyLoadImage
            src={`/seoul_image_3.jpg`}
            effect="blur"
          />
        </section>
      </div>

      <section
        className={cx(!isMobile ? "church_bottom" : "church_bottom_mobile")}
      >
        <LazyLoadImage
          src={`${imgUrlOriginPath}/${churchEngName}_back.jpg`}
          effect="blur"
          alt={""}
        />
      </section>
    </Layout>
  );
};

export default Church;
