import React from 'react';
import SpotlightItem from "./SpotlightItem/SpotlightItem";
import spotlightData from './SpotlightData';
import {isMobile} from "react-device-detect";

import styles from './Spotlight.scss';
import classNames from "classnames";

const cx = classNames.bind(styles);

const Spotlight = () => {
    return (
        <div className={cx('MainSpotlight', {mobile: isMobile})}>
            <div className={cx('item_wrap')}>
                {/*tag, title(제목), content(내용), byID(글쓴이)*/}
                {
                    spotlightData.map((v, i) => (
                        <SpotlightItem
                            key={i}
                            imgSrc={v.imgSrc}
                            title={v.title}
                            content={v.content}
                            link={v.link}
                            // tag={v.tag}
                            // byID={v.byID}
                        />
                    ))
                }
            </div>
        </div>
    );
}

export default Spotlight;