const imgUrlOriginPath = `${process.env.REACT_APP_IMAGE_URL}/press/all/`;

const srcFormatter = (name) => {
    return imgUrlOriginPath + name + '.jpg';
}

const dateFormatter = (date) => {
    const str = date.toString();
    return '20' + str.slice(0,2) + '.' + str.slice(2,4) + '.' +str.slice(4,6);
}

export const AllNewsData = [
    {
        image: srcFormatter(220828),
        title: '신천지 ‘위아원 청년봉사단’ 7만명 단체헌혈 시작',
        source: '스카이데일리',
        date: dateFormatter(220828),
        link: 'https://www.skyedaily.com/news/news_view.html?ID=165640',
    },
    {
        image: srcFormatter(220822),
        title: '이만희 총회장 성경 속 생명나무와 선악나무의 참뜻과 실체 밝혀',
        source: '경북신문',
        date: dateFormatter(220822),
        link: 'http://www.kbsm.net/news/view.php?idx=361047',
    },
    {
        image: srcFormatter(220821),
        title: '청년봉사단 위아원 과천지부, 서울 사당동 수해 복구 지원',
        source: '경인투데이뉴스',
        date: dateFormatter(220821),
        link: 'http://www.ktin.net/h/contentxxx.html?code=newsbd&idx=798870&hmidx=1',
    },
    {
        image: srcFormatter(220819),
        title: '청년봉사단 위아원, 신천지자원봉사단 양평지부 4일간 수해복구 봉사활동 전개',
        source: '내외일보',
        date: dateFormatter(220819),
        link: 'http://www.naewoeilbo.com/news/articleView.html?idxno=543452',
    },
    {
        image: srcFormatter(220817),
        title: '위아원 서울경기남부지역연합회, 수도권 일대 수해 복구 총력',
        source: '글로벌이코노믹',
        date: dateFormatter(220817),
        link: 'https://news.g-enews.com/ko-kr/news/article/news_all/202208171300277399a141c47927_1/article.html?md=20220817151157_U',
    },
    {
        image: srcFormatter(220816),
        title: '위아원 청년봉사단 성남지부, 수해복구 봉사활동 구슬땀',
        source: '중도일보',
        date: dateFormatter(220816),
        link: 'http://www.joongdo.co.kr/web/view.php?key=20220816010004351',
    },
    {
        image: srcFormatter('220816-1'),
        title: '이만희 총회장 “깨어있다면 이뤄진 계시록 확인하고 따져봐야”',
        source: '신아일보',
        date: dateFormatter(220816),
        link: 'http://www.shinailbo.co.kr/news/articleView.html?idxno=1587304',
    },
    {
        image: srcFormatter(220802),
        title: '국내 최대 청년봉사단 \'위아원(We Are One)\'출범(중앙일보)',
        source: '중앙일보',
        date: dateFormatter(220802),
        link: 'https://www.joongang.co.kr/article/25091418#home',
    },
    {
        image: srcFormatter(220421),
        title: '신천지의 ‘헌혈 기적’... 3일 만에 6천명분 달성',
        source: '페이퍼뉴스',
        date: dateFormatter(220421),
        link: 'http://www.papernews1.com/news/articleView.html?idxno=12159'
    },
    {
        image: srcFormatter(220322),
        title: '신천지예수교회, 하나님의 선민 이스라엘은 세 가지',
        source: '서울매일신문',
        date: dateFormatter(220322),
        link: 'http://www.smaeil.com/news/articleView.html?idxno=400288'
    },
]
