import React from 'react';
import {isMobile} from "react-device-detect";

import styles from './PageTitle.scss';
import classNames from "classnames";
const cx = classNames.bind(styles);

const PageTitle = ({title}) => {

    const GraduationBackground = process.env.REACT_APP_IMAGE_URL + "/graduation/title_background.png";
    const GraduationCircle = process.env.REACT_APP_IMAGE_URL + "/graduation/title_circle.png";

    if (title === '110기 10만 수료식') {
        return (
            <section
                className={cx(!isMobile ? 'page_title' : 'page_title_mobile', 'graduation')}
                style={{backgroundImage: `url(${GraduationBackground})`}}
            >
                <img src={GraduationCircle} alt={''}/>
                <span>{title}</span>
                <img src={GraduationCircle} alt={''}/>
            </section>
        );
    }

    return (
        <section className={cx(!isMobile ? 'page_title' : 'page_title_mobile')}>
            <span>{title}</span>
        </section>
    );
}

export default PageTitle;
