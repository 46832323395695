import React from 'react';
import {isMobile} from "react-device-detect";
import {LazyLoadImage} from "react-lazy-load-image-component";
import Layout from "../Layout/Layout";
import PageTitle from "../PageTitle/PageTitle";

import styles from './Symbol.scss';
import classNames from "classnames";

const cx = classNames.bind(styles);

const Symbol = () => {

    const JamesImg = process.env.REACT_APP_IMAGE_URL + '/symbol/james.png';
    const JewelImg = process.env.REACT_APP_IMAGE_URL + '/symbol/jewel.png';
    const DonkeyImg = process.env.REACT_APP_IMAGE_URL + '/symbol/donkey.png';
    const CharacterImg = process.env.REACT_APP_IMAGE_URL + '/symbol/character.png';

    return (
        <Layout>
            <PageTitle title='상징'/>

            <div className={cx(!isMobile ? 'Symbol' : 'Symbol_mobile')}>
                <section>
                    <LazyLoadImage src={JamesImg} alt={''} effect="blur"/>
                    <div className={cx('desc')}>
                        <div className={cx('title')}>
                            예수님의 제자 야고보
                        </div>
                        {
                            !isMobile ?
                                <div className={cx('content')}>
                                    <b>예수님의 열한 번째 제자.</b><br/>
                                    동명이인인 세배대의 아들 야고보와 구분하기 위해<br/>
                                    "작은 야고보(알패오의 아들)"라고 불린다.<br/>
                                    야고보는 예수님이 십자가 지실 때 어머니 마리아, 형제 요셉(요세)과 함께<br/>
                                    멀리서 바라봤던 인물이다<span style={{color: '#717071'}}>(마 27:56, 막 15:40, 47, 막 16:2).</span><br/>
                                    그는 94세까지 블레셋과 애굽에서 전도하다가 순교했다.<br/>
                                    성전 높은 탑에서 아래로 던져도 죽지 않아 나무 망치로 머리를 맞고 애굽에서 순교했다.
                                </div>
                                :
                                <div className={cx('content')}>
                                    <b>예수님의 열한 번째 제자.</b><br/>
                                    동명이인인 세배대의 아들 야고보와 구분하기 위해<br/>
                                    "작은 야고보(알패오의 아들)"라고 불린다.<br/>
                                    야고보는 예수님이 십자가 지실 때 어머니 마리아,<br/>
                                    형제 요셉(요세)과 함께 멀리서 바라봤던 인물이다<br/>
                                    <span style={{color: '#717071'}}>(마 27:56, 막 15:40, 47, 막 16:2).</span><br/>
                                    그는 94세까지 블레셋과 애굽에서 전도하다가 순교했다.<br/>
                                    성전 높은 탑에서 아래로 던져도 죽지 않아 나무 망치로 <br/>
                                    머리를 맞고애굽에서 순교했다.
                                </div>
                        }
                    </div>
                </section>

                <div className={cx('hr_line')}/>

                {
                    !isMobile ?
                        <section className={cx('middle')}>
                            <div className={cx('desc')}>
                                <div className={cx('title')}>
                                    청옥 보석
                                </div>
                                <div className={cx('content')}>
                                    그 성의 성곽의 기초석은 각색 보석으로 꾸몄는데<br/>
                                    첫째 기초석은 벽옥이요 둘째는 남보석이요 셋째는 옥수요<br/>
                                    넷째는 녹보석이요 다섯째는 홍마노요 여섯째는 홍보석이요<br/>
                                    일곱째는 황옥이요 여덟째는 녹옥이요 아홉째는 담황옥이요<br/>
                                    열째는 비취옥이요 <b style={{fontSize: '2rem'}}>열한째는 청옥이요</b> 열두째는 자수정이라<br/>
                                    <span style={{color: '#717071'}}>- 요한계시록 21:19~20 -</span><br/>
                                    <br/>
                                    서울야고보지파를 상징하는 보석은 청옥입니다.<br/>
                                    청옥은 천국 성곽 12기초석 중 하나입니다.<br/>
                                    이는 구약 때 하나님을 섬기던 제사장들의 흉배에 사용된<br/>
                                    12가지 보석(출 28:17-29, 38:9-14)과 같은 의미입니다<br/>
                                </div>
                            </div>
                            <LazyLoadImage src={JewelImg} alt={''} effect="blur"/>
                        </section>
                        :
                        <section className={cx('middle')}>
                            <LazyLoadImage src={JewelImg} alt={''} effect="blur"/>
                            <div className={cx('desc')}>
                                <div className={cx('title')}>
                                    청옥 보석
                                </div>
                                <div className={cx('content')}>
                                    그 성의 성곽의 기초석은 각색 보석으로 꾸몄는데<br/>
                                    첫째 기초석은 벽옥이요 둘째는 남보석이요<br/>
                                    셋째는 옥수요 넷째는 녹보석이요<br/>
                                    다섯째는 홍마노요 여섯째는 홍보석이요<br/>
                                    일곱째는 황옥이요 여덟째는 녹옥이요<br/>
                                    아홉째는 담황옥이요 열째는 비취옥이요<br/>
                                    <b style={{fontSize: '1.4rem'}}>열한째는 청옥이요</b> 열두째는 자수정이라<br/>
                                    <span style={{color: '#717071'}}>- 요한계시록 21:19~20 -</span><br/>
                                    <br/>
                                    서울야고보지파를 상징하는 보석은 청옥입니다.<br/>
                                    청옥은 천국 성곽 12기초석 중 하나입니다.<br/>
                                    이는 구약 때 하나님을 섬기던 제사장들의 흉배에 사용된<br/>
                                    12가지 보석(출 28:17-29, 38:9-14)과 같은 의미입니다<br/>
                                </div>
                            </div>
                        </section>
                }

                <div className={cx('hr_line')}/>

                <section className={cx('bottom')}>
                    <div className={cx('bottom_div')}>
                        <div className={cx('bottom_img')}>
                            <LazyLoadImage src={DonkeyImg} alt={''} effect="blur"/>
                        </div>
                        <div className={cx('desc')}>
                            <div className={cx('title')}>
                                나귀
                            </div>
                            <div className={cx('content')}>
                                잇사갈은 양의 우리 사이에 꿇어앉은 건장한 나귀로다<br/>
                                <div className={cx('verse')}>- 창세기 49:14 -</div>
                            </div>
                        </div>
                    </div>

                    <div className={cx('bottom_div')}>
                        <div className={cx('bottom_img')}>
                            <LazyLoadImage src={CharacterImg} alt={''} effect="blur"/>
                        </div>
                        <div className={cx('desc')}>
                            <div className={cx('title')}>
                                서약이와 이룸이
                            </div>
                            <div className={cx('content')}>
                                나귀 모티브로 제작된 서울야고보지파 캐릭터
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
}

export default Symbol;
