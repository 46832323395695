import React, {useEffect} from "react";
import {isMobile} from "react-device-detect";
import {useNavigate} from "react-router-dom";

import styles from './Seminar.scss';
import classNames from "classnames";
const cx = classNames.bind(styles);

const Seminar = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isMobile) {
            navigate('/');
        }
    }, [navigate]);

    return (
        // <Layout>
        //     <PageTitle title={'2023 말씀 대성회'}/>

            <div className={cx('seminar')}>
                <section className={cx('top')}>
                    {/*<img className={cx('scjLogo')} src={scjLogo} alt={''}/>*/}

                </section>

                <iframe
                  className='youtube'
                  src="https://www.youtube.com/embed/_ZUFEdms3ss?autoplay=1&mute=1"
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen></iframe>

                <section className={cx('mid')}>
                    <div className={cx('mid_title common')}>
                        2023 목회자 성경 세미나 <span>PASTOR BIBLE SEMINAR</span>
                    </div>
                    <div className={cx('mid_sub1 common')}>
                        <div className={cx('sub_title')}>행사취지</div>
                        <p>
                            지구촌 수 많은 사람들 가운데 약 20억명의 기독교인들이 천국과 영생을 위해 신앙하고 있습니다.
                            예수님의 십자가를 통해 죄사함을 얻은 우리 신앙인들은 땅 끝까지 복음을 전하고 진리를 온 세상에
                            전파해야 할 사명이 있기에 성경 66권을 통달하고 계시록의 성취와 실상을 증거받아야 합니다.<br/><br/>
                            이 때에 <strong>2023년에만 해외 15개국 280개 교회</strong>가 '신천지예수교 증거장막성전'으로
                            <strong>간판을 교체</strong>하고 <strong>82개국에서 총 6,636건의 MOU</strong>를 체결했으며
                            MOU를 체결한 목회자 중 <strong>5,363명이 현재 계시신학을 정립</strong>하고 있습니다.
                        </p>
                    </div>
                    <div className={cx('mid_sub2 common')}>
                        <div>
                            현재까지 <strong>총 27개국에서 554개 교회</strong>
                        </div>
                        <div>
                            소속목회자 수 <strong>605명</strong>
                        </div>
                        <div>
                            소속 교인 수 <strong>36,438명</strong>
                        </div>
                    </div>
                    <div className={cx('mid_sub3 common')}>
                        신천지가 <span>급성장 하는 이유,</span><br/>
                        궁금하신가요?<br/><br/>
                        먼저는 참 하나님이 함께 하시는 교회이며<br/>
                        <strong>길이 되시는 예수님의 말씀</strong>으로 <strong>가감없이</strong> 전하고 있습니다.<br/><br/>

                        <strong>하나님</strong>께서 <strong>약속</strong>하시고,<br/>
                        <strong>하나님</strong>께서 이루시는<br/>
                        계시록의 성취실상을 <strong>직접 듣고 확인</strong>하여<br/>
                        새로운 시대를 열어갈 <strong>주인공</strong>이 되시길 바랍니다.
                    </div>
                </section>

                <section className={cx('bottom')}>
                    <div className={cx('sec')}>
                        <div className={cx('eng')}>SCHEDULE & PROGRAM</div>
                        <div className={cx('kor')}>일정 및 프로그램</div>
                    </div>
                    <ul>
                        <li>
                            <div className={cx('tag')}>주제</div>
                            <div className={cx('cont')}>
                                <span>The WAY</span><br/>
                                계 22:16 나 예수는 교회들을 위하여 내 사자를 보내어 이것들을 너희에게 증거하게 하였노라
                            </div>
                        </li>
                        <li>
                            <div className={cx('tag')}>대상</div>
                            <div className={cx('cont')}>목회자</div>
                        </li>
                        <li>
                            <div className={cx('tag')}>일시</div>
                            <div className={cx('cont')}>2023.04.22 15-17시</div>
                        </li>
                        <li>
                            <div className={cx('tag')}>신청일자</div>
                            <div className={cx('cont')}>~ 4월 20일까지</div>
                        </li>
                    </ul>
                    <div className={cx('sec')}>
                        <div className={cx('eng')}>SPEAKER</div>
                        <div className={cx('kor')}>강의자 소개</div>
                    </div>
                    <div className={cx('speaker')} />
                    <div className={cx('speaker_intro')}>
                        <div className={cx('title')}>
                            <span>총회장님</span><br/>
                            신천지예수교증거장막성전
                        </div>
                        <p className={cx('txt')}>
                            “ 우리가 성경으로 신앙을 시작하였으니, 성경대로 신앙을 해야 소망을 이룰 것입니다.<br/>
                            천국이 정말 가까이 왔습니다. 하나님과 예수님과 천사들이 기다리고 계십니다.”<br/><br/>

                            예수님께서는 교회들을 위해 사자를 보내주신다고 약속하셨습니다.<br/>
                            예수님께서 믿으라 하신 것은 계시록의 성취이오니, 현장에 오셔서 성경대로 이루어진 내용을 확인해보시는 귀한 시간 되시기 바랍니다.
                        </p>
                    </div>
                </section>
            </div>
        // </Layout>
    );
}

export default Seminar;
