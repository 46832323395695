import { useNavigate, useLocation, Routes, Route } from "react-router-dom";
import { Fragment, useState } from "react";
import Icon from "./components/Icon";
import { relationNavigation } from "./relationNavigation";
import styles from "./RelationCenterRoute.scss";
import classNames from "classnames";
const cx = classNames.bind(styles);

const RelationCenterRoute = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [openNav, setOpenNav] = useState(() => false);

  const toggleNav = () => {
    setOpenNav((last) => !last);
  };

  const handleNavClick = (path) => () => {
    if (path === pathname) {
      return;
    }
    setOpenNav(false);
    navigate(path);
  };

  const renderNavigationRecursive = (obj, lastUrl) => {
    return Object.entries(obj).map(([key, value]) => {
      if (key === "component") {
        return <Fragment key={`${key}_component`} />;
      }
      if (typeof value === "string") {
        return (
          <li key={key} onClick={handleNavClick(lastUrl || "/")} className={cx("itemLink")}>
            <Icon icon="menu" /> {value}
          </li>
        );
      }
      return (
        <li key={key} className={cx("itemList")}>
          <ul className={cx("recursiveList")}>{renderNavigationRecursive(value, `${lastUrl}/${key}`)}</ul>
        </li>
      );
    });
  };

  const relationCenterRecursive = (obj, url) => {
    const Component = obj.component;
    return (
      <Fragment key={url}>
        <Route path={url} element={<Component />} />
        {Object.entries(obj).map(([key, value]) => {
          if (key === "component" || key === "title") {
            return <Fragment key={`${url}_${key}`} />;
          }
          return relationCenterRecursive(obj[key], `${url}/${key}`);
        })}
      </Fragment>
    );
  };

  return (
    <div className={cx("relationCenterLayout")}>
      <nav className={cx("navigation")}>
        <button className={cx("toggleBtn")} onClick={toggleNav}>
          <Icon icon="menu" />
        </button>
        <ul className={cx("navList")} style={{ transform: openNav ? "" : "translateX(-100%)", backdropFilter: "blur(10px)" }}>
          {renderNavigationRecursive(relationNavigation, "/relation-center")}
        </ul>
      </nav>
      <Routes>{relationCenterRecursive(relationNavigation, "/")}</Routes>
    </div>
  );
};

export default RelationCenterRoute;
