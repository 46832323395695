import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Layout from "../Layout/Layout";

import {isMobile} from "react-device-detect";
import styles from './Prize.scss';
import classNames from "classnames";
import PageTitle from "../PageTitle/PageTitle";
const cx = classNames.bind(styles);

const imgUrlOriginPath = `${process.env.REACT_APP_IMAGE_URL}/prize`;
const ContentsInfos = {'a':3, 'b':6, 'c':4, 'd':8};

const Prize = () => {
    return (
        <Layout>
            <PageTitle title={'수상 내역'}/>
            <div className={cx(!isMobile ? 'prize' : 'prize_mobile')}>
                {
                    Object.keys(ContentsInfos).map((info, i) => (
                        <section className={i%2 === 1 ? 'gray_back' : ''}>
                            <div className={`container v${i+1}`}>
                                {
                                    [...Array(ContentsInfos[info]).keys()].map(n =>
                                        <LazyLoadImage src={`${imgUrlOriginPath}/${info}${n+1}.jpg`} effect={'blur'}/>
                                    )
                                }
                            </div>
                        </section>
                    ))
                }
            </div>
        </Layout>
    );
}

export default Prize;
