import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Layout from "../Layout/Layout";

import {isMobile} from "react-device-detect";
import styles from './Corona.scss';
import classNames from "classnames";
import PageTitle from "../PageTitle/PageTitle";

const cx = classNames.bind(styles);

const imgUrlOriginPath = `${process.env.REACT_APP_IMAGE_URL}/corona`;
const ContentsInfos = [
    {
        title: '다다익선',
        desc: <>
            다다익선(多多益善) 프로젝트는 힐링·나눔·기부·환경을 주제로<br/>
            ‘기쁨은 나누면 배가 되고 슬픔은 나누면 반이 된다’는 사회 정서를 반영하였습니다.<br/>
            지역 주민들이 봉사자로 참여해 정서에 좋은 화초를 일정 기간 길러 도움이 필요한 사람에게<br/>
            기증하는 방식입니다. 봉사자와 수혜자 모두가 한마음으로 이어지자는 취지도 꾸려졌습니다.
        </>,
        descMobile: <>
            다다익선(多多益善) 프로젝트는 힐링·나눔·기부·환경을 주제로
            ‘기쁨은 나누면 배가 되고 슬픔은 나누면 반이 된다’는 사회 정서를 반영하였습니다.
            지역 주민들이 봉사자로 참여해 정서에 좋은 화초를 일정 기간 길러 도움이 필요한 사람에게
            기증하는 방식입니다. 봉사자와 수혜자 모두가 한마음으로 이어지자는 취지도 꾸려졌습니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/a1.jpg`,
            `${imgUrlOriginPath}/a2.jpg`,
            `${imgUrlOriginPath}/a3.jpg`,
            `${imgUrlOriginPath}/a4.jpg`,
            `${imgUrlOriginPath}/a5.jpg`,
        ]
    },
    {
        title: '착한기부 착한소비 캠페인',
        desc: <>
            착한기부 착한소비 캠페인은 코로나19로 어려움을 겪고 있는 소외된 이웃을 위한 캠페인 입니다.<br/>
            선정된 ‘착한 가게’에서 신천지 성도가 생활 소비를 하면 가게 수입금 3-5%를<br/>
            소외계층에 기부하는 활동입니다. 신천지 자원봉사단은 정기적으로 ‘착한 가게’를 홍보하여,<br/>
            일상에서 필요한 소비를 하면서 어려운 이웃도 도울 수 있는 착한 소비를 권장했습니다.
        </>,
        descMobile: <>
            착한기부 착한소비 캠페인은 코로나19로 어려움을 겪고 있는 소외된 이웃을 위한 캠페인 입니다.
            선정된 ‘착한 가게’에서 신천지 성도가 생활 소비를 하면 가게 수입금 3-5%를
            소외계층에 기부하는 활동입니다. 신천지 자원봉사단은 정기적으로 ‘착한 가게’를 홍보하여,
            일상에서 필요한 소비를 하면서 어려운 이웃도 도울 수 있는 착한 소비를 권장했습니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/b1.jpg`,
            `${imgUrlOriginPath}/b2.jpg`,
            `${imgUrlOriginPath}/b3.jpg`,
            `${process.env.REACT_APP_IMAGE_URL}/neighbor/b4.jpg`,
            `${process.env.REACT_APP_IMAGE_URL}/neighbor/b5.jpg`,
        ]
    },
    {
        title: '방역 봉사',
        desc: <>
            코로나 바이러스 유행이 장기화되면서 신천지 자원봉사단은 오해와 편견을 뒤로하고<br/>
            코로나 바이러스로부터 지역사회의 안전을 지키기 위해 2020년 9월부터 6개월간 무료로<br/>
            정기 방역을 실시했습니다. 전국에 있는 각 지부에서 무료 정기 방역봉사가 실시된 가운데<br/>
            서울. 경기동부연합 신천지 자원봉사단은 회원 99명이 28일에 걸쳐 68곳의 정기 방역 봉사를 마쳤습니다.
        </>,
        descMobile: <>
            코로나 바이러스 유행이 장기화되면서 신천지 자원봉사단은 오해와 편견을 뒤로하고
            코로나 바이러스로부터 지역사회의 안전을 지키기 위해 2020년 9월부터 6개월간 무료로
            정기 방역을 실시했습니다. 전국에 있는 각 지부에서 무료 정기 방역봉사가 실시된 가운데
            서울. 경기동부연합 신천지 자원봉사단은 회원 99명이 28일에 걸쳐 68곳의 정기 방역 봉사를 마쳤습니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/c1.jpg`,
            `${imgUrlOriginPath}/c2.jpg`,
            `${imgUrlOriginPath}/c3.jpg`,
            `${imgUrlOriginPath}/c4.jpg`,
            `${imgUrlOriginPath}/c5.jpg`,
        ]
    },
    {
        title: '나라사랑 평화나눔',
        desc: <>
            신천지 자원봉사단은 나라를 위해 희생하신 순국선열들을 기리고,<br/>
            한반도에 평화가 깃들기를 염원하며 나라사랑 평화나눔 행사를 진행해왔습니다.<br/>
            평화의 필요성을 다시 상기시키는 좋은 취지에 동감하는 외부봉사인원들이 늘어가면서<br/>
            지역사회 내 관련기관과 업무협약도 맺어가고 있습니다.
        </>,
        descMobile: <>
            신천지 자원봉사단은 나라를 위해 희생하신 순국선열들을 기리고,
            한반도에 평화가 깃들기를 염원하며 나라사랑 평화나눔 행사를 진행해왔습니다.
            평화의 필요성을 다시 상기시키는 좋은 취지에 동감하는 외부봉사인원들이 늘어가면서
            지역사회 내 관련기관과 업무협약도 맺어가고 있습니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/d1.jpg`,
            `${imgUrlOriginPath}/d2.jpg`,
            `${imgUrlOriginPath}/d3.jpg`,
            `${imgUrlOriginPath}/d4.jpg`,
            `${imgUrlOriginPath}/d5.jpg`
        ]
    },
    {
        title: '담벼락 이야기',
        desc: <>
            계층, 종교를 초월한 봉사, 담벼락 이야기는 낡고 오래된 담벼락을<br/>
            사랑과 평화의 이야기를 담은 밝고 생동감 있는 새로운 예술작품으로 만들어 내는 봉사입니다.
        </>,
        descMobile: <>
            계층, 종교를 초월한 봉사, 담벼락 이야기는 낡고 오래된 담벼락을
            사랑과 평화의 이야기를 담은 밝고 생동감 있는 새로운 예술작품으로 만들어 내는 봉사입니다.
        </>,
        imgUrlList: [
            `${imgUrlOriginPath}/e1.jpg`,
            `${imgUrlOriginPath}/e2.jpg`,
            `${imgUrlOriginPath}/e3.jpg`,
            `${imgUrlOriginPath}/e4.jpg`,
        ],
        imgUrlListMobile: [
            `${imgUrlOriginPath}/e1.jpg`,
            `${imgUrlOriginPath}/e4.jpg`,
            `${imgUrlOriginPath}/e2.jpg`,
            `${imgUrlOriginPath}/e3.jpg`,
        ],
    }
];

const TitleBox = ({title, desc}) => (
    <div className={cx('title_box')}>
        <h1>{title}</h1>
        <p>{desc}</p>
    </div>
);
const ImgBox = ({v, list}) => (
    <div className={cx(`img_box iv${v}`)}>
        {list.map((url, i) =>
            <LazyLoadImage
                src={url} effect="blur"
                wrapperClassName={putBigImgClass(v, i)}
            />)}
    </div>
);
const putBigImgClass = (v, i) => {
    if (!isMobile) {
        if (v === 1 && i === 1) return 'big_img';
        else {
            if (i === 1 || i === 2) return `big_img${i}`;
        }
    } else {
        if (v === 1 && i === 2) return 'big_img';
        else {
            if (i === 2 || i === 3) return `big_img${i}`;
        }
    }
};
const Sections = ({sectionVer, cn, title, desc, imgUrlList, idx}) => (
    <section id={cn} className={cx(`sv${sectionVer+1}`)} key={idx}>
        <div className={cx('container')}>
            <TitleBox title={title} desc={desc} />
            <ImgBox v={idx !== ContentsInfos.length-1 ? 1 : 2} list={imgUrlList} />
        </div>
    </section>
);

const Corona = () => {
    return (
        <Layout>
            <PageTitle title={'코로나 위기극복'}/>
            <div className={cx(!isMobile ? 'corona' : 'corona_mobile')}>
                {
                    ContentsInfos.map((info, i) =>
                        <Sections
                            sectionVer={i%2}
                            cn={`section${i+1}`}
                            title={info.title}
                            desc={!isMobile ? info.desc : info.descMobile}
                            imgUrlList={isMobile && i === ContentsInfos.length-1 ? info.imgUrlListMobile : info.imgUrlList}
                            idx={i}
                        />
                    )
                }
            </div>
        </Layout>
    );
}

export default Corona;
