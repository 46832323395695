import RelationCenter from "./RelationCenter";
import HistoryContent from "./RelationCenterHistory/HistoryContent/HistoryContent";
import RelationCenterHistory from "./RelationCenterHistory/RelationCenterHistory";
import Reservation from "./Reservation/Reservation";

export const relationNavigation = {
  title: "홈",
  component: RelationCenter,
  history: {
    title: "홍보관",
    component: RelationCenterHistory,
    intro: {
      title: "인트로",
      component: HistoryContent,
    },
    media: {
      title: "미디어 1관, 2관",
      component: HistoryContent,
    },
    shincheonji: {
      title: "빛의 항해",
      component: HistoryContent,
    },
    books: {
      title: "빛의 시작과 미래",
      component: HistoryContent,
    },
    "twelve-tribe": {
      title: "영원한 빛의 나라",
      component: HistoryContent,
    },
    eduzion: {
      title: "빛의 첫 열매",
      component: HistoryContent,
    },
    earth: {
      title: "빛의 전파",
      component: HistoryContent,
    },
  },
  reservation: {
    title: "예약하기",
    component: Reservation,
  },
};
