const intro_main = process.env.REACT_APP_IMAGE_URL + '/main/m1.jpg';
const church_main = '/seoul_image_3.jpg';
const painting_main = process.env.REACT_APP_IMAGE_URL + '/painting_main.jpg';
const marketing_main = process.env.REACT_APP_IMAGE_URL + '/main/m3.jpg';
const channel_main = process.env.REACT_APP_IMAGE_URL + '/main/m4.jpg';
const building_main = process.env.REACT_APP_IMAGE_URL + '/buliding_main.jpg'

const spotlightData = [
    {
        imgSrc: intro_main,
        title: '지파소개',
        content: '예수님의 열두 제자 중 열한 번째, 야고보의 이름을 받은 서울야고보지파입니다.',
        link: '/intro/greetings'
    },
    {
        imgSrc: church_main,
        title: '교회소개',
        content: '서울야고보지파에 모인 가족들, 우리 교회를 소개합니다.',
        link: '/church/seoul'
    },
    {
        imgSrc: painting_main,
        title: '사회공헌',
        content: '세상의 빛과 소금이 되기를 바라며! 나라 사랑, 이웃 사랑에 앞장서는 서울야고보',
        link: '/volunteer/neighbor'
    },
    {
        imgSrc: marketing_main,
        title: '교회홍보',
        content: '아름다운 신천지, 서울야고보입니다. 서울야고보의 이모저모를 소개합니다!',
        link: '/marketing/graduation'
    },
    {
        imgSrc: channel_main,
        title: '파란하늘TV',
        content: '신앙 성장을 돕는 다양한 콘텐츠가 가득한 서울야고보지파 공식 유튜브 채널입니다.',
        link: '/bluesky'
    },
    {
        imgSrc: building_main,
        title: '오시는 길',
        content: '서울야고보지파 위치 안내',
        link: '/direction'
    }
];

export default spotlightData;