const imgUrlOriginPath = `${process.env.REACT_APP_IMAGE_URL}/press/branch/`;

const srcFormatter = (name) => {
    return imgUrlOriginPath + name + '.jpg';
}

const dateFormatter = (date) => {
    const str = date.toString();
    return '20' + str.slice(0,2) + '.' + str.slice(2,4) + '.' +str.slice(4,6);
}

export const BranchNewsData = [
    {
        image: srcFormatter(220827),
        title: '[천지포토] 헌혈 캠페인 참여하는 위아원 회원들',
        source: '천지일보',
        date: dateFormatter(220827),
        link: 'https://www.newscj.com/article/20220827580052'
    },
    {
        image: srcFormatter('220827-1'),
        title: '[천지포토] 신천지 청년 자원봉사단 위아원, 단체헌혈로 혈액수급난 극복 동참',
        source: '천지일보',
        date: dateFormatter(220827),
        link: 'https://www.newscj.com/article/20220827580066'
    },
    {
        image: srcFormatter(220823),
        title: '신천지 자원봉사단, 광복절 맞아 보훈단체 후원',
        source: '노원신문',
        date: dateFormatter(220823),
        link: 'http://nowon.newsk.com/front/news/view.do?articleId=ARTICLE_00019572'
    },
    {
        image: srcFormatter(220822),
        title: '신천지 자원봉사단, 감사함을 담아 삼계탕 나눔',
        source: '내외일보',
        date: dateFormatter(220822),
        link: 'http://www.naewoeilbo.com/news/articleView.html?idxno=544249'
    },
    {
        image: srcFormatter(220813),
        title: '청년봉사단 위아원, 신천지자원봉사단과 연합 ‘수해 가정 복구에 구슬땀’',
        source: '노원신문',
        date: dateFormatter(220813),
        link: 'http://www.naewoeilbo.com/news/articleView.html?idxno=540616'
    },
    {
        image: srcFormatter(220509),
        title: '신천지예수교회, 중등 11과 구리교회 강창훈 강사 강의 진행',
        source: '브레이크 뉴스',
        date: dateFormatter(220509),
        link: 'https://www.breaknews.com/893582'
    },
    {
        image: srcFormatter(220501),
        title: '신천지 서울야고보 지파, 제1차 생명나눔 헌혈 캠페인에 1012명 참여',
        source: '기호일보',
        date: dateFormatter(220501),
        link: 'http://www.kihoilbo.co.kr/news/articleView.html?idxno=977312'
    },
    {
        image: srcFormatter(220429),
        title: '신천지 서울야고보지파 "헌혈봉사로 사랑 실천"',
        source: '시사매거진',
        date: dateFormatter(220429),
        link: 'http://www.sisamagazine.co.kr/news/articleView.html?idxno=442771'
    },
    {
        image: srcFormatter(220418),
        title: '[천지포토] 헌혈 앞장선 ‘유영주 서울야고보지파장’',
        source: '천지일보',
        date: dateFormatter(220418),
        link: 'https://www.newscj.com/970935'
    },
    {
        image: srcFormatter(220404),
        title: '신천지예수교회, 중등 11과 구리교회 강창훈 강사 강의 진행',
        source: '시사매거진',
        date: dateFormatter(220404),
        link: 'http://www.sisamagazine.co.kr/news/articleView.html?idxno=437820'
    },
    {
        image: srcFormatter(220322),
        title: '신천지예수교 서울동부지부, (사)희망세상농업포럼과 MOU체결 사회공헌 적극 협조',
        source: '내외일보',
        date: dateFormatter(220322),
        link: 'http://www.naewoeilbo.com/news/articleView.html?idxno=453194'
    },
    {
        image: srcFormatter(220210),
        title: '신천지 서울야고보지파, 초등세미나 11과 ‘비유한 바다, 어부, 그물, 고기, 배’ 천국 비밀 증거',
        source: 'G글로벌경제신문',
        date: dateFormatter(220210),
        link: 'http://www.getnews.co.kr/news/articleView.html?idxno=571935'
    },
]
