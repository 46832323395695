import { useEffect, useState } from "react";
import styles from "./ImageRotator.scss";
import classNames from "classnames";
const cx = classNames.bind(styles);

let timeout = null;

const ImageRotator = ({ srcs, duration, delay, imageProps = {}, ...props }) => {
  const { className, ...image } = imageProps;

  const [active, setActive] = useState(() => 0);

  useEffect(() => {
    let nextIndex = active + 1;
    if (nextIndex >= srcs.length) {
      nextIndex = 0;
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setActive(nextIndex);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [active, delay, srcs]);

  return (
    <figure className={cx("image-rotator")} {...props}>
      {Array.isArray(srcs) &&
        srcs.map((src, index) => (
          <img key={`${src}_${index}`} className={`${cx("rotate-img")} ${index === active ? cx("active") : ""}`} style={{ transition: `all ${duration}ms` }} src={src} {...image} />
        ))}
    </figure>
  );
};

export default ImageRotator;
