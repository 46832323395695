import React from 'react';
import styles from './NotFound.scss'
import classNames from "classnames";

const cx = classNames.bind(styles);
const FlowerVase = process.env.REACT_APP_IMAGE_URL + '/not_found/flower_vase.jpeg';

const NotFound = () => {

    return (
        <div className={cx('not-found')}>
            <img src={FlowerVase} alt={''}/>
            <p>찾으시는<br/>페이지가 없습니다.</p>
        </div>
    );
}

export default NotFound;