import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// components
import Main from "./components/Main/Main";
import NotFound from "./components/NotFound/NotFound";
import Greetings from "./components/Greetings/Greetings";
import Graduation from "./components/Graduation/Graduation";
import Culture from "./components/Culture/Culture";
import Direction from "./components/Direction/Direction";
import BlueSkyTv from "./components/BlueSkyTv/BlueSkyTv";
import History from "./components/History/History";
import Symbol from "./components/Symbol/Symbol";
import Regions from "./components/Regions/Regions";
import Church from "./components/Church/Church";
import Vision from "./components/Vision/Vision";
import Press from "./components/Press/Press";
import Nation from "./components/Nation/Nation";
import Neighbor from "./components/Neighbor/Neighbor";
import Prize from "./components/Prize/Prize";
import Corona from "./components/Corona/Corona";
import Seminar from "./components/Seminar/Seminar";
import RelationCenterRoute from "./components/RelationCenter/RelationCenterRoute";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="intro/greetings" element={<Greetings />} />
        <Route path="intro/vision" element={<Vision />} />
        <Route path="intro/history" element={<History />} />
        <Route path="intro/symbol" element={<Symbol />} />
        <Route path="intro/regions" element={<Regions />} />
        <Route path="church/:churchName" element={<Church />} />
        <Route path="volunteer/nation" element={<Nation />} />
        <Route path="volunteer/neighbor" element={<Neighbor />} />
        <Route path="volunteer/corona" element={<Corona />} />
        <Route path="volunteer/prize" element={<Prize />} />
        <Route path="marketing/seminar" element={<Seminar />} />
        <Route path="marketing/graduation" element={<Graduation />} />
        <Route path="marketing/press" element={<Press />} />
        <Route path="marketing/culture" element={<Culture />} />
        <Route path="bluesky" element={<BlueSkyTv />} />
        <Route path="direction" element={<Direction />} />
        <Route path="relation-center/*" element={<RelationCenterRoute />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
