import React from 'react';
import {isMobile} from "react-device-detect";
import Layout from "../Layout/Layout";
import Spotlight from "../Spotlight/Spotlight";
import MainSlide from "./MainSlide/MainSlide";

import styles from './Main.scss';
import classNames from "classnames";

const cx = classNames.bind(styles);

const Main = () => {
    return (
        <Layout>
            <MainSlide/>
            <div className={cx(!isMobile ? 'Main' : 'Main_mobile')}>
                <Spotlight/>
            </div>
        </Layout>
    );
}

export default Main;
