import React, { useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

import styles from "./Layout.scss";
import classNames from "classnames";
import Popup from "../RelationCenter/components/popup";

const cx = classNames.bind(styles);

const Layout = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={cx("Layout")}>
      <Header />
      <Popup />
      <div className={cx("Children")}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
