import React from 'react';
import {isMobile} from "react-device-detect";

import styles from './Sports.scss';
import classNames from "classnames";

const cx = classNames.bind(styles);

const Sports = () => {
    const images = [];
    for (let i = 1; i <= 18; i++) {
        images.push(process.env.REACT_APP_IMAGE_URL + `/sports/${String(i).padStart(2,'0')}.jpg`)
    }

    const Background1 = process.env.REACT_APP_IMAGE_URL + '/sports/background.png';
    const Background2 = process.env.REACT_APP_IMAGE_URL + '/sports/background2.png';
    const Background3 = process.env.REACT_APP_IMAGE_URL + '/sports/background3.png';
    const Background4 = process.env.REACT_APP_IMAGE_URL + '/sports/background4.png';
    const Person1 = process.env.REACT_APP_IMAGE_URL + '/sports/person1.png';
    const Person2 = process.env.REACT_APP_IMAGE_URL + '/sports/person2.png';
    const Person3 = process.env.REACT_APP_IMAGE_URL + '/sports/person3.png';

    if (!isMobile) {
        return (
            <div className={cx('Sports')}>
                <div className={cx('background_wrap')}>
                    <div className={cx('background')} style={{backgroundImage: `url(${Background1})`}}/>
                    <div className={cx('background', 'reverse')} style={{backgroundImage: `url(${Background1})`}}/>
                    <div className={cx('background')} style={{backgroundImage: `url(${Background2})`}}/>
                    <div className={cx('background', 'reverse')} style={{backgroundImage: `url(${Background2})`}}/>
                    <div className={cx('background')} style={{backgroundImage: `url(${Background3})`}}/>
                    <div className={cx('background', 'reverse')} style={{backgroundImage: `url(${Background3})`}}/>
                    <div className={cx('background')} style={{backgroundImage: `url(${Background4})`}}/>
                </div>

                <div className={cx('person_wrap')}>
                    <img src={Person1} alt={''}/>
                    <img src={Person2} alt={''}/>
                    <img src={Person3} alt={''}/>
                </div>

                <div className={cx('desc_wrap')}>
                    <div className={cx('desc')}>
                        <h1>제1회 신천지 전국 체육 대회</h1>
                        <p>1993. 10. 30~31</p>
                    </div>
                    <div className={cx('desc')}>
                        <h1>제2회</h1>
                        <h1>신천지 전국 체육 대회 및 신천지 제11주년 수장절 감사 예배</h1>
                        <p>1995. 9. 23~24</p>
                    </div>
                    <div className={cx('desc')}>
                        <h1>신천지 16주년 수장절 기념예배 및 제3회 체전</h1>
                        <p>2000. 9. 23~24</p>
                    </div>
                    <div className={cx('desc')}>
                        <h1>성북야고보지파시온축구단발대식</h1>
                        <p>2001. 7. 8</p>
                    </div>
                    <div className={cx('desc')}>
                        <h1>제1회 신천지 총회장배 체육 대회</h1>
                        <p>2001. 8. 31</p>
                    </div>
                    <div className={cx('desc')}>
                        <h1>서울 경기 4개 지파 축구 대회</h1>
                        <p>2001. 11. 11</p>
                    </div>
                    <div className={cx('desc')}>
                        <h1>제1회 성북야고보 지파 체육 대회</h1>
                        <p>2004. 6. 13</p>
                    </div>
                    <div className={cx('desc')}>
                        <h1>제4회 신천지 전국체전 및 수장절 감사 예배</h1>
                        <p>2004. 9. 13</p>
                    </div>
                    <div className={cx('desc')}>
                        <h1>제5회 하늘 문화 예술 체전</h1>
                        <p>2008. 10. 5</p>
                    </div>
                    <div className={cx('desc')}>
                        <h1>제6회 하늘 문화 예술 체전</h1>
                        <p>2012. 9. 16</p>
                    </div>
                    <div className={cx('desc')}>
                        <p>네 시작은 미약하였으나</p>
                        <h1>네 나중은 심히 창대하리라</h1>
                        <p>- 욥8:7 -</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    {images.map(element => <img src={element} alt={''}/>)}
                </div>
            </div>
        );
    } else {
        return (
            <div className={cx('Sports_mobile')}>

                <div className={cx('img_wrap')}>
                    <img src={images[0]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>제1회 신천지 전국 체육 대회</h1>
                        <p>1993. 10. 30~31</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[1]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>제2회 신천지 전국 체육 대회 및 신천지 제11주년 수장절 감사 예배</h1>
                        <p>1995. 9. 23~24</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[2]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>신천지 16주년 수장절 기념예배 및 제3회 체전</h1>
                        <p>2000. 9. 23~24</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[3]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>성북야고보지파시온축구단발대식</h1>
                        <p>2001. 7. 8</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[4]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>제1회 신천지 총회장배 체육 대회</h1>
                        <p>2001. 8. 31</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[5]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>서울 경기 4개 지파 축구 대회</h1>
                        <p>2001. 11. 11</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[6]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>제1회 성북야고보 지파 체육 대회</h1>
                        <p>2004. 6. 13</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[8]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>제4회 신천지 전국체전 및 수장절 감사 예배</h1>
                        <p>2004. 9. 13</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[10]} alt={''}/>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[12]} alt={''}/>
                </div>

                <div className={cx('img_wrap', 'large')}>
                    <img src={images[11]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>제5회 하늘 문화 예술 체전</h1>
                        <p>2008. 10. 5</p>
                    </div>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[13]} alt={''}/>
                </div>

                <div className={cx('img_wrap')}>
                    <img src={images[14]} alt={''}/>
                </div>

                <div className={cx('img_wrap', 'large')}>
                    <img src={images[15]} alt={''}/>
                </div>

                <div className={cx('img_wrap', 'large')}>
                    <img src={images[17]} alt={''}/>
                </div>

                <div className={cx('img_wrap', 'large')}>
                    <img src={images[16]} alt={''}/>
                    <div className={cx('desc')}>
                        <h1>제6회 하늘 문화 예술 체전</h1>
                        <p>2012. 9. 16</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sports;