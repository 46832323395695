import React from "react";
import Layout from "../Layout/Layout";

import {isMobile} from "react-device-detect";
import styles from './Vision.scss';
import classNames from "classnames";
import PageTitle from "../PageTitle/PageTitle";
const cx = classNames.bind(styles);

const Vision = () => {
    return (
        <Layout>
            <div className={cx(!isMobile ? 'vision' : 'vision_mobile')}>
                <PageTitle title={'비전'}/>
                {
                    !isMobile ?
                        <div className={cx('wrap')}>
                            <div className={cx('left_side')}>
                                <div className={cx('top')}>
                                    <span className={cx('a')}>2024년</span>
                                    <span className={cx('b')}>서울야고보지파<strong>비전</strong></span>
                                    <span className={cx('c')}>VISION</span>
                                </div>
                                <div className={cx('middle')}>
                                    <div className={cx('dot')}/>
                                    <div className={cx('dot')}/>
                                    <div className={cx('dot')}/>
                                </div>
                                <div className={cx('bottom')}>
                                    <p className={'dotdot_start'}>&#8220;</p>
                                    <p className={cx('txt')}>
                                        비상하는 청옥 빛<br/>
                                        <span>서울야고보</span>
                                    </p>
                                    <p className={'dotdot_end'}>&#8221;</p>
                                </div>
                            </div>
                            <div className={cx('dot_bar')}>
                                <div className={cx('dot')}/>
                                <div className={cx('dot')}/>
                                <div className={cx('dot')}/>
                            </div>
                            <div className={cx('right_side')}>
                                <div className={cx('box_wrap')}>
                                    <ul>
                                        <li><video src={`${process.env.REACT_APP_IMAGE_URL}/vision/v1.mp4`} type="video/mp4" loop='true' controls/></li>
                                        <li><video src={`${process.env.REACT_APP_IMAGE_URL}/vision/v2.mp4`} type="video/mp4" loop='true' controls/></li>
                                        <li><video src={`${process.env.REACT_APP_IMAGE_URL}/vision/v3.mp4`} type="video/mp4" loop='true' controls/></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                            <section className={cx('top')}>
                                <span className={cx('a')}>2024년</span>
                                <span className={cx('b')}>서울야고보지파<strong>비전</strong></span>
                                <span className={cx('c')}>VISION</span>
                            </section>
                            <section className={cx('middle')}>
                                <div className={cx('box')}>
                                    <video className={cx('shadow_a')} src={`${process.env.REACT_APP_IMAGE_URL}/vision/v1.mp4#t=0.1`} type="video/mp4" loop='true' controls/>
                                </div>
                                <p className={cx('desc')}>묵은 관습 버리기</p>
                                <div className={cx('box')}>
                                    <video className={cx('shadow_b')} src={`${process.env.REACT_APP_IMAGE_URL}/vision/v2.mp4#t=0.1`} type="video/mp4" loop='true' controls/>
                                </div>
                                <p className={cx('desc')}>새로운 시스템 구축</p>
                                <div className={cx('box')}>
                                    <video className={cx('shadow_c')} src={`${process.env.REACT_APP_IMAGE_URL}/vision/v3.mp4#t=0.1`} type="video/mp4" loop='true' controls/>
                                </div>
                                <p className={cx('desc')}>세계로 도약</p>
                            </section>
                            <section className={cx('bottom')}>
                                <p className={'dotdot_start'}>&#8220;</p>
                                <p className={cx('up')}> 비상하는 청옥 빛</p>
                                <p className={cx('down')}>서울야고보</p>
                                <p className={'dotdot_end'}>&#8221;</p>
                            </section>
                        </>
                }
            </div>
        </Layout>
    );
}

export default Vision;
