import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Layout from "../Layout/Layout";

import {isMobile} from "react-device-detect";
import styles from './Nation.scss';
import classNames from "classnames";
import PageTitle from "../PageTitle/PageTitle";
const cx = classNames.bind(styles);

const imgUrlOriginPath = `${process.env.REACT_APP_IMAGE_URL}/nation`;
const ContentsInfos = [
    {
        title: '찾아가는 건강닥터',
        mainImgUrl: `${imgUrlOriginPath}/a1.jpg`,
        desc: <>
            찾아가는 건강닥터는 신천지에서 주관하는 의료봉사입니다.<br/>
            기본적인 의료서비스를 받기 어려운 소외계층과 외국인 근로자들에게 무료로<br/>
            의료서비스를 제공하여 사회 의료 취약계층의 다양한 사회 문제 해결을 위해 노력하고 있습니다.
        </>,
        descMobile: <>
            찾아가는 건강닥터는 신천지에서 주관하는 의료봉사입니다.
            기본적인 의료서비스를 받기 어려운 소외계층과 외국인 근로자들에게 무료로
            의료서비스를 제공하여 사회 의료 취약계층의 다양한 사회 문제 해결을 위해 노력하고 있습니다.
        </>,
        bigImgUrl: `${imgUrlOriginPath}/a2.jpg`,
        imgUrlList: [
            `${imgUrlOriginPath}/a3.jpg`,
            `${imgUrlOriginPath}/a4.jpg`,
            `${imgUrlOriginPath}/a5.jpg`
        ]
    }, {
        title: '담벼락 이야기',
        mainImgUrl: `${imgUrlOriginPath}/b1.jpg`,
        desc: <>
            계층, 종교를 초월한 봉사, 담벼락 이야기는 낡고 오래된 담벼락을<br/>
            사랑과 평화의 이야기를 담은 밝고 생동감 있는 새로운 예술작품으로 만들어 내는 봉사입니다.
        </>,
        descMobile: <>
            계층, 종교를 초월한 봉사, 담벼락 이야기는 낡고 오래된 담벼락을
            사랑과 평화의 이야기를 담은 밝고 생동감 있는 새로운 예술작품으로 만들어 내는 봉사입니다.
        </>,
        bigImgUrl: `${imgUrlOriginPath}/b2.jpg`,
        imgUrlList: [
            `${imgUrlOriginPath}/b3.jpg`,
            `${imgUrlOriginPath}/b4.jpg`,
            `${imgUrlOriginPath}/b5.jpg`
        ]
    },
    {
        title: '생명나눔 헌혈 캠페인',
        mainImgUrl: `${imgUrlOriginPath}/c1.jpg`,
        desc: <>
            신천지 자원봉사단은 국가적 혈액수급 위기를 극복하고 혈액의 안정적 확보에<br/>
            도움이 되고자 생명나눔 캠페인, "생명 ON"을 진행하였습니다.
        </>,
        descMobile: <>
            신천지 자원봉사단은 국가적 혈액수급 위기를 극복하고 혈액의 안정적 확보에
            도움이 되고자 생명나눔 캠페인, "생명 ON"을 진행하였습니다.
        </>,
        bigImgUrl: `${imgUrlOriginPath}/c2.jpg`,
        imgUrlList: [
            `${imgUrlOriginPath}/c3.jpg`,
            `${imgUrlOriginPath}/c4.jpg`,
            `${imgUrlOriginPath}/c5.jpg`
        ]
    },
    {
        title: '나라사랑 평화나눔',
        mainImgUrl: `${imgUrlOriginPath}/d1.jpg`,
        desc: <>
            신천지 자원봉사단은 나라를 위해 희생하신 순국선열들을 기리고,<br/>
            한반도에 평화가 깃들기를 염원하며 나라사랑 평화나눔 행사를 진행해왔습니다.<br/>
            평화의 필요성을 다시 상기시키는 좋은 취지에 동감하는 외부봉사인원들이 늘어가면서<br/>
            지역사회 내 관련기관과 업무협약도 맺어가고 있습니다.
        </>,
        descMobile: <>
            신천지 자원봉사단은 나라를 위해 희생하신 순국선열들을 기리고,
            한반도에 평화가 깃들기를 염원하며 나라사랑 평화나눔 행사를 진행해왔습니다.
            평화의 필요성을 다시 상기시키는 좋은 취지에 동감하는 외부봉사인원들이 늘어가면서
            지역사회 내 관련기관과 업무협약도 맺어가고 있습니다.
        </>,
        bigImgUrl: `${imgUrlOriginPath}/d2.jpg`,
        imgUrlList: [
            `${imgUrlOriginPath}/d3.jpg`,
            `${imgUrlOriginPath}/d4.jpg`,
            `${imgUrlOriginPath}/d5.jpg`
        ]
    },
    {
        title: '자연아 푸르자',
        mainImgUrl: `${imgUrlOriginPath}/e1.jpg`,
        desc: <>
            자연과 인류의 조화로운 공존을 위해 습지보존 활동, 주요 시설 및 문화재 보호를 위한 환경정화,<br/>
            재난재해로 인한 환경복구 활동, 환경 보호 관련 공익 캠페인 활동, 쓰레기 무단 투기 지역 꽃길<br/>
            조성 등 지구촌 생태계 보존 및 환경지킴이 활동을 실시하여 아름다운 자연을 다음 세대에<br/>
            유산으로 물려줄 수 있도록 노력하고 있습니다.
        </>,
        descMobile: <>
            자연과 인류의 조화로운 공존을 위해 습지보존 활동, 주요 시설 및 문화재 보호를 위한 환경정화,
            재난재해로 인한 환경복구 활동, 환경 보호 관련 공익 캠페인 활동, 쓰레기 무단 투기 지역 꽃길
            조성 등 지구촌 생태계 보존 및 환경지킴이 활동을 실시하여 아름다운 자연을 다음 세대에
            유산으로 물려줄 수 있도록 노력하고 있습니다.
        </>,
        bigImgUrl: `${imgUrlOriginPath}/e2.jpg`,
        imgUrlList: [
            `${imgUrlOriginPath}/e3.jpg`,
            `${imgUrlOriginPath}/e4.jpg`,
            `${imgUrlOriginPath}/e5.jpg`
        ]
    },
];

const TitleBox = ({title, desc, bigImgUrl}) => (
    <div className={cx('title_box')}>
        <h1>{title}</h1>
        <p>{desc}</p>
        <LazyLoadImage src={bigImgUrl} effect="blur"/>
    </div>
);
const ImgBox = ({list}) => (
    <div className={cx('img_box')}>
        {list.map((url) => <LazyLoadImage src={url} effect="blur"/>)}
    </div>
);
const Sections = ({v, cn, title, desc, bigImgUrl, imgUrlList, idx}) => (
    <section id={cn} className={cx(`v${v+1}`)} key={idx}>
        <div className={cx('container')}>
            <div className={cx('left')}>
                {
                    !isMobile ?
                        v === 0
                        ? <TitleBox title={title} desc={desc} bigImgUrl={bigImgUrl} />
                        : <ImgBox list={imgUrlList} />
                    : <TitleBox title={title} desc={desc} bigImgUrl={bigImgUrl} />
                }
            </div>
            <div className={cx('right')}>
                {
                    !isMobile ?
                        v === 0
                        ? <ImgBox list={imgUrlList} />
                        : <TitleBox title={title} desc={desc} bigImgUrl={bigImgUrl} />
                    : <ImgBox list={imgUrlList} />
                }
            </div>
        </div>
    </section>
);

const Nation = () => {
    return (
        <Layout>
            <PageTitle title={'나라 사랑'}/>
            <div className={cx(!isMobile ? 'nation' : 'nation_mobile')}>
                <section className={cx('top')}>
                    <div className={cx('container')}>
                        {ContentsInfos.map((info, i) =>
                            <a className={cx('box main_box')} href={`#section${i + 1}`}>
                                <LazyLoadImage src={`${info.mainImgUrl}`} effect="blur" />
                                <div className={'title'}><p>{info.title}</p></div>
                            </a>
                        )}
                        <div className={cx('box main_icon')}>
                            <div className={cx('back')}>
                                <LazyLoadImage src={`${process.env.REACT_APP_IMAGE_URL}/volunteer_logo.png`} effect="blur" />
                            </div>
                        </div>
                    </div>
                </section>
                {
                    ContentsInfos.map((info, i) =>
                        <Sections
                            v={i%2}
                            cn={`section${i+1}`}
                            title={info.title}
                            desc={!isMobile ? info.desc : info.descMobile}
                            bigImgUrl={info.bigImgUrl}
                            imgUrlList={info.imgUrlList}
                            idx={i}
                        />
                    )
                }
            </div>
        </Layout>
    );
}

export default Nation;
