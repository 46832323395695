import React from "react";
import Layout from "../Layout/Layout";
import PageTitle from "../PageTitle/PageTitle";
import {isMobile} from "react-device-detect";

import styles from './Regions.scss';
import classNames from "classnames";
const cx = classNames.bind(styles);

const Regions = () => {
    const sky = process.env.REACT_APP_IMAGE_URL + '/regions/sky.png';
    const mountain = process.env.REACT_APP_IMAGE_URL + '/regions/north_mt.jpg';

    return (
        <Layout>
            <PageTitle title={'지경 소개'}/>

            <div className={cx(!isMobile ? 'regions' : 'regions_mobile')}>
                {
                    !isMobile &&
                        // <img src={`${process.env.REACT_APP_IMAGE_URL}/regions/sky.jpg`} alt={''}
                    <div className={cx('pc_bg')} style={{backgroundImage: `url(${sky})`}}/>
                }

                <div className={cx('wrap')}>
                    <section className={cx('top')}>
                        {
                            isMobile &&
                            <div>
                                <img src={`${process.env.REACT_APP_IMAGE_URL}/regions/sky_m.jpg`} alt={''}/>
                            </div>
                        }
                        <p className={cx('main_txt')}>청옥처럼 빛나는<br/>서울야고보 지경</p>
                        <p className={cx('eng')}>Shincheonji Seoul-James five regions introduction</p>
                        {
                            !isMobile ?
                                <div className={cx('desc')}>
                                    신천지예수교회는 성경의 약속대로 창조된<br/>
                                    <strong>총회와 12지파</strong>가 있습니다(마 6:10, 계 4장, 계 7장).<br/>
                                    전 세계 31개국에 분포해 있는 신천지예수교회는<br/>
                                    각 국가와 지역별로 12지파에 소속되어 있는 가운데,<br/>
                                    우리 <strong>서울야고보지파는 국내 5개 교회와 해외 11개 교회</strong>가 있습니다.
                                </div>
                                :
                                <div className={cx('desc')}>
                                    신천지예수교회는 성경의 약속대로 창조된<br/>
                                    <strong>총회와 12지파</strong>가 있습니다(마 6:10, 계 4장, 계 7장).<br/>
                                    전 세계 31개국에 분포해 있는 신천지예수교회는<br/>
                                    각 국가와 지역별로 12지파에 소속되어 있는 가운데,<br/>
                                    우리 <strong>서울야고보지파는 국내 5개 교회와<br/>
                                    해외 11개 교회</strong>가 있습니다.
                                </div>
                        }
                    </section>

                    <section className={cx('region_detail')}>
                        <img src={`/south.png`} alt={''}/>
                    </section>

                    <section className={cx('world')}>
                        <img src={`${process.env.REACT_APP_IMAGE_URL}/regions/world.png`} alt={''}/>
                    </section>
                </div>

                <section className={cx('bottom')}>
                    <div className={cx('bg')} style={{backgroundImage: `url(${mountain})`}}/>
                </section>
            </div>
        </Layout>
    );
}

export default Regions;
