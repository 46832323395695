import React from 'react';
import {LazyLoadImage} from "react-lazy-load-image-component";
import Layout from "../Layout/Layout";
import {isMobile} from "react-device-detect";
import PageTitle from "../PageTitle/PageTitle";

import styles from './Graduation.scss';
import classNames from "classnames";

const cx = classNames.bind(styles);

const Graduation = () => {

    const GraduationPicture = process.env.REACT_APP_IMAGE_URL + "/graduation/picture.png";
    const CeremonyLogo = process.env.REACT_APP_IMAGE_URL + "/graduation/ic_ceremony_logo.png";
    const CeremonyBG = process.env.REACT_APP_IMAGE_URL + "/graduation/ic_ceremony_bg.png";
    const IntroFlag = process.env.REACT_APP_IMAGE_URL + "/graduation/ic_flag.png";
    const IntroFlagMob = process.env.REACT_APP_IMAGE_URL + "/graduation/ic_flag_mob.png";
    const pic1 = process.env.REACT_APP_IMAGE_URL + "/graduation/ceremony_pic1.jpg";
    const pic2 = process.env.REACT_APP_IMAGE_URL + "/graduation/ceremony_pic2.jpg";
    const pic3 = process.env.REACT_APP_IMAGE_URL + "/graduation/ceremony_pic3.jpg";
    const pic4 = process.env.REACT_APP_IMAGE_URL + "/graduation/ceremony_pic4.jpg";
    const pic5 = process.env.REACT_APP_IMAGE_URL + "/graduation/ceremony_pic5.jpg";

    return (
        <Layout>
            <PageTitle title={'110기 10만 수료식'}/>

            <div className={cx(!isMobile ? 'Graduation' : 'Graduation_mobile')}>
                <div className={cx('Main_banner')}>
                    <p className={cx('banner_txt')}>
                        단일 교단 사상 최대 규모 <br/> 신천지 10만 수료식
                    </p>
                    <LazyLoadImage src={GraduationPicture} alt={'GraduationPicture'} effect="blur"/>
                </div>
                <div className={cx('Logo')}>
                    <LazyLoadImage src={CeremonyLogo} alt={CeremonyLogo} effect="blur"/>
                </div>
                <div className={cx('intro_txt')} style={{backgroundImage: !isMobile ? `url(${IntroFlag})` : `url(${IntroFlagMob})`}}>
                    2019년, 시온기독교선교센터는<br/>
                    110기 연합 수료식에서<br/>
                    <span>10만 3,764명</span>의 신학 수료생을 배출했습니다.<br/><br/>
                    전 세계 112곳에서 동시에 진행된 수료식은<br/>
                    국내 언론과 기성 교단의 이목을 집중시켰으며,<br/>
                    올해도 10만명 이상이 수료할 예정입니다.<br/><br/>
                    110기 연합 수료식<br/>2019.11.10
                </div>
                <ul>
                    <li>
                        <div className={cx('with_round')}>
                            주 재림의 역사<br/>실상의 형상
                        </div>
                        <div className={cx('line')}/>
                        <div className={cx('pic_box')}>
                            <LazyLoadImage src={pic1} alt={pic1} effect="blur"/>
                        </div>
                    </li>
                    <li>
                        <div className={cx('mid_txt')}>
                            <p>
                                주 재림과 추수의 역사<br/>
                                12지파 10만 수료
                            </p>
                            <div className={cx('bar')}/>
                            <span>103,764명</span>
                        </div>
                        <div className={cx('pic_box')}>
                            <LazyLoadImage src={pic2} alt={pic2} effect="blur"/>
                        </div>
                    </li>
                    <li>
                        <div className={cx('mid_txt')}>
                            <span>추수된 자는<br/>하나님의 씨로 난 자</span>
                            <div className={cx('bar')}/>
                            <p>
                                추수되지 못한 자는<br/>
                                하나님의 씨로 나지 아니한 자
                            </p>
                        </div>
                        <div className={cx('pic_box')}>
                            <LazyLoadImage src={pic3} alt={pic3} effect="blur"/>
                        </div>
                    </li>
                    <li>
                        <div className={cx('mid_txt')}>
                            <p>
                                추수된 자를 보라!<br/>추수된 자들의 춤!
                            </p>
                            <div className={cx('bar')}/>
                            <p>
                                구원받아 기쁘고<br/>즐거워 춤을 추며<br/>
                                하나님께 감사의 영광을 올린다!
                            </p>
                        </div>
                        <div className={cx('pic_box')}>
                            <LazyLoadImage src={pic4} alt={pic4} effect="blur"/>
                        </div>
                    </li>
                    <li>
                        <div className={cx('with_round')}>
                            예수님의<br/>피의 능력!
                        </div>
                        <div className={cx('mid_txt')}>
                            <p>12지파 하나님의 씨로 난</p>
                            <div className={cx('line')}/>
                            <p>빛의 자녀들을 보라!</p>
                        </div>
                        <div className={cx('pic_box')}>
                            <LazyLoadImage src={pic5} alt={pic5} effect="blur"/>
                        </div>
                    </li>
                </ul>
                <div className={cx('bg_box')}>
                    <img src={CeremonyBG} alt={CeremonyBG}/>
                </div>
            </div>
        </Layout>
    );
}

export default Graduation;
