import { useNavigate } from "react-router-dom";

import styles from "./ListNavigation.scss";
import classNames from "classnames";
const cx = classNames.bind(styles);

const ListNavigationItem = ({ href, className, style, ...props }) => {
  const navigate = useNavigate();

  const handleClickItem = () => {
    if (href) {
      navigate(href);
    }
  };

  return (
    <li
      className={cx("history-nav-item")}
      onClick={handleClickItem}
      style={{
        cursor: href ? "pointer" : "unset",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        position: "relative",
      }}
      {...props}
    />
  );
};

export default ListNavigationItem;
