import React, {useState} from 'react';
import {isMobile} from "react-device-detect";
import Layout from "../Layout/Layout";
import PageTitle from "../PageTitle/PageTitle";
import Sports from "./Sports/Sports";
import Performance from "./Performance/Performance";

import styles from './Culture.scss';
import classNames from "classnames";
import Tabs from "../Tabs/Tabs";

const cx = classNames.bind(styles);

const Culture = () => {

    const [currentTab, setCurrentTab] = useState('performance');

    const tabs = [
        {
            name: 'performance',
            kor: '예술공연문화'
        },
        {
            name: 'sports',
            kor: '체육문화'
        }
    ]

    return (
        <Layout>

            <PageTitle title='하늘문화'/>
            <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab}/>

            <div className={cx(!isMobile ? 'Culture' : 'Culture_mobile')}>
                {currentTab === 'sports' && <Sports/>}
                {currentTab === 'performance' && <Performance/>}
            </div>
        </Layout>
    );
}

export default Culture;
