export const HistoryData = {
    top: [
        {year: '1991. 01. 14', content: '신천지 총회'},
        {year: '1992. 12. 28', content: '신학원 10, 11기 수료식(장소: 안양 본관)'},
        {year: '1993. 03. 14', content: '신천지 창립 9주년 기념 예배'},
        {year: '1994. 08. 28', content: '신학원 20기 제 1반 수료식'},
        {year: '1991.', content: '전국 청년회 체육 대회'},
        {year: '1993. 10. 30-31', content: '1회 신천지 체전'},
        {year: '1995. 03. 14', content: '보좌 구성 (장소: 수원 공설 운동장)'}
    ],
    topPicture: [
        {src: process.env.REACT_APP_IMAGE_URL + '/history/top1.jpg', desc: '1991. 01. 14 신천지 총회'},
        {src: process.env.REACT_APP_IMAGE_URL + '/history/top2.jpg', desc: '1995. 03. 14 보좌구성'},
    ],
    middle: [
        {year: '1986. 08. 20', content: '성북성전 설립 (석관동, 18평)'},
        {year: '1988. 03. 01', content: '성북성전 확장이전 (석관동, 40평)'},
        {year: '1992. 03. 08', content: '성북성전 확장이전 (미아동, 140평)'},
        {year: '1995. 03. 14', content: '성북야고보지파로 명명'},
        {year: '1996. 11. 01', content: '성북성전 확장이전 (창동, 146평)'},
        {year: '2003. 08. 01', content: '포천교회 봉헌예배'},
        {year: '2006. 04. 30', content: '성북성전 확장이전 (상계동)'},
        {year: '2010. 08. 01', content: '서울야고보지파로 명명'},
        {year: '2012. 08. 01', content: '해외 11개 교회 1,240명 전입'},
        {year: '2013. 08. 01', content: '서울교회로 명명'},
        {year: '2014. 07. 02', content: '한성교회(동대문교회) 봉헌예배'},
        {year: '2015. 10. 01', content: '상계동 서울교회 성전 증축 (2~5층)'},
        {year: '2015. 11. 06', content: '의정부교회 봉헌예배'}
    ],
    middlePicture: [
        {src: process.env.REACT_APP_IMAGE_URL + '/history/middle1.jpg', desc: '1988. 03. 01 성북 성전 건립(석관동)'},
        {src: process.env.REACT_APP_IMAGE_URL + '/history/middle2.jpg', desc: '1996. 11. 01 성북성전 확장이전(창동)'},
        {src: process.env.REACT_APP_IMAGE_URL + '/history/middle3.jpg', desc: '2006. 04. 30 성북성전 확장이전(상계동)'},
        {src: process.env.REACT_APP_IMAGE_URL + '/history/middle4.jpg', desc: '2015. 10. 01 서울성전 증축(상계동)'},
    ],
    bottom: [
        {year: '2003. 04. 13', content: '제 61기 11반 수료식'},
        {year: '2005. 02. 27', content: '제 73기 11반 수료식'},
        {year: '2005. 11. 27', content: '제 76기 11반 수료식'},
        {year: '2007. 11. 25', content: '제 84기 11반 수료식'},
        {year: '2008. 12. 07', content: '제 87기 11반 수료식'},
        {year: '2009. 12. 06', content: '제 90기 11반 수료식'},
        {year: '2011. 03. 06', content: '제 93기 11반 수료식'},
        {year: '2011. 11. 20', content: '제 94기 연합 수료식'},
        {year: '2012. 10. 28', content: '제 96기 11반 수료식'},
        {year: '2013. 11. 24', content: '제 98기 11반 수료식'},
        {year: '2014. 11. 28', content: '제 100기 연합 수료식'},
        {year: '2015. 11. 01', content: '제 102기 11반 수료식'},
        {year: '2016. 10. 23', content: '제 104기 연합 수료식'},
        {year: '2017. 11. 19', content: '제 106기 9, 11반 연합 수료식'},
        {year: '2018. 11. 18', content: '제 108기 서울경기 연합 수료식'},
        {year: '2019. 11. 10', content: '제 110기 10만 수료식'},
        {year: '2020. 12.', content: '제 111기 온라인 수료식'},
        {year: '2021. 12. 26', content: '제 112기 온라인 수료식'}
    ],
    bottomPicture: [
        {src: process.env.REACT_APP_IMAGE_URL + '/history/bottom1.jpg', desc: '제 87기 11반 수료식'},
        {src: process.env.REACT_APP_IMAGE_URL + '/history/bottom2.jpg', desc: '제 93기 11반 수료식'},
        {src: process.env.REACT_APP_IMAGE_URL + '/history/bottom3.jpg', desc: '제 106기 9, 11반 연합 수료식'},
        {src: process.env.REACT_APP_IMAGE_URL + '/history/bottom4.jpg', desc: '제 110기 10만 수료식'},
    ]
};