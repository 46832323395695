import React from 'react';
import {isMobile} from "react-device-detect";
import styles from './Performance.scss';
import classNames from "classnames";

const cx = classNames.bind(styles);

const Section1 = process.env.REACT_APP_IMAGE_URL + '/performance/1.jpg';
const Section1Img1 = process.env.REACT_APP_IMAGE_URL + '/performance/1-1.jpg';
const Section1Img2 = process.env.REACT_APP_IMAGE_URL + '/performance/1-2.jpg';
const Section1Img3 = process.env.REACT_APP_IMAGE_URL + '/performance/1-3.jpg';
const Section1Img4 = process.env.REACT_APP_IMAGE_URL + '/performance/1-4.jpg';
const Section2 = process.env.REACT_APP_IMAGE_URL + '/performance/2.jpg';
const Section2Img1 = process.env.REACT_APP_IMAGE_URL + '/performance/2-1.jpg';
const Section2Img2 = process.env.REACT_APP_IMAGE_URL + '/performance/2-2.jpg';
const Section2Img3 = process.env.REACT_APP_IMAGE_URL + '/performance/2-3.jpg';
const Section2Img4 = process.env.REACT_APP_IMAGE_URL + '/performance/2-4.jpg';
const Section3 = process.env.REACT_APP_IMAGE_URL + '/performance/3.jpg';
const Section3Img1 = process.env.REACT_APP_IMAGE_URL + '/performance/3-1.jpg';
const Section3Img2 = process.env.REACT_APP_IMAGE_URL + '/performance/3-2.jpg';
const Section3Img3 = process.env.REACT_APP_IMAGE_URL + '/performance/3-3.jpg';
const Section3Img4 = process.env.REACT_APP_IMAGE_URL + '/performance/3-4.jpg';
const Section4 = process.env.REACT_APP_IMAGE_URL + '/performance/4.jpg';
const Section4Img1 = process.env.REACT_APP_IMAGE_URL + '/performance/4-1.jpg';
const Section4Img2 = process.env.REACT_APP_IMAGE_URL + '/performance/4-2.jpg';
const Section4Img3 = process.env.REACT_APP_IMAGE_URL + '/performance/4-3.jpg';
const Section4Img4 = process.env.REACT_APP_IMAGE_URL + '/performance/4-4.jpg';
const Section5 = process.env.REACT_APP_IMAGE_URL + '/performance/5.jpg';
const Section5Img1 = process.env.REACT_APP_IMAGE_URL + '/performance/5-1.jpg';
const Section5Img2 = process.env.REACT_APP_IMAGE_URL + '/performance/5-2.jpg';
const Section5Img3 = process.env.REACT_APP_IMAGE_URL + '/performance/5-3.jpg';
const Section5Img4 = process.env.REACT_APP_IMAGE_URL + '/performance/5-4.jpg';
const Section6 = process.env.REACT_APP_IMAGE_URL + '/performance/6.jpg';
const Section6Img1 = process.env.REACT_APP_IMAGE_URL + '/performance/6-1.jpg';
const Section6Img2 = process.env.REACT_APP_IMAGE_URL + '/performance/6-2.jpg';
const Section6Img3 = process.env.REACT_APP_IMAGE_URL + '/performance/6-3.jpg';
const Section6Img4 = process.env.REACT_APP_IMAGE_URL + '/performance/6-4.jpg';
const Section7 = process.env.REACT_APP_IMAGE_URL + '/performance/7.jpg';
const Section7Img1 = process.env.REACT_APP_IMAGE_URL + '/performance/7-1.jpg';
const Section7Img2 = process.env.REACT_APP_IMAGE_URL + '/performance/7-2.jpg';
const Section7Img3 = process.env.REACT_APP_IMAGE_URL + '/performance/7-3.jpg';
const Section7Img4 = process.env.REACT_APP_IMAGE_URL + '/performance/7-4.jpg';
const Section8 = process.env.REACT_APP_IMAGE_URL + '/performance/8.jpg';
const Section8Img1 = process.env.REACT_APP_IMAGE_URL + '/performance/8-1.jpg';
const Section8Img2 = process.env.REACT_APP_IMAGE_URL + '/performance/8-2.jpg';
const Section8Img3 = process.env.REACT_APP_IMAGE_URL + '/performance/8-3.jpg';
const Section8Img4 = process.env.REACT_APP_IMAGE_URL + '/performance/8-4.jpg';

const PerformanceData = [
    {
        position: 'right',
        title: '마칭밴드',
        titleEn: 'Marching Band',
        background: Section1,
        img: [
            Section1Img1,
            Section1Img2,
            Section1Img3,
            Section1Img4,
        ]
    },
    {
        position: 'left',
        title: '무용단',
        titleEn: 'Dance Troupe',
        background: Section2,
        img: [
            Section2Img1,
            Section2Img2,
            Section2Img3,
            Section2Img4,
        ]
    },
    {
        position: 'right',
        title: '응원단',
        titleEn: 'Cheering Squad',
        background: Section3,
        img: [
            Section3Img1,
            Section3Img2,
            Section3Img3,
            Section3Img4,
        ]
    },
    {
        position: 'left',
        title: '음악콘서트',
        titleEn: 'Music Concert',
        background: Section4,
        img: [
            Section4Img1,
            Section4Img2,
            Section4Img3,
            Section4Img4,
        ]
    },
    {
        position: 'right',
        title: '전시',
        titleEn: 'Exhibition',
        background: Section5,
        img: [
            Section5Img1,
            Section5Img2,
            Section5Img3,
            Section5Img4,
        ]
    },
    {
        position: 'left',
        title: '농악대',
        titleEn: 'Nongack Band',
        background: Section6,
        img: [
            Section6Img1,
            Section6Img2,
            Section6Img3,
            Section6Img4,
        ]
    },
    {
        position: 'right',
        title: '취타대',
        titleEn: 'Chwita Band',
        background: Section7,
        img: [
            Section7Img1,
            Section7Img2,
            Section7Img3,
            Section7Img4,
        ]
    },
    {
        position: 'left',
        title: '극단',
        titleEn: 'Theater Company',
        background: Section8,
        img: [
            Section8Img1,
            Section8Img2,
            Section8Img3,
            Section8Img4,
        ]
    },
];

const Section = ({position, title, titleEn, background, img}) => {
    if (!isMobile) {
        return (
            <section className={cx(position)} style={{backgroundImage: `url(${background})`}}>
                <div className={cx('title_wrap')}>
                    <div className={cx('title')}>{title}</div>
                    <div className={cx('title_en')}>{titleEn}</div>
                </div>
                <div className={cx('picture_wrap')}>
                    <div className={cx('picture')} style={{backgroundImage: `url(${img[0]})`}}/>
                    <div className={cx('picture')} style={{backgroundImage: `url(${img[1]})`}}/>
                    <div className={cx('picture')} style={{backgroundImage: `url(${img[2]})`}}/>
                </div>
            </section>
        );
    } else {
        return (
            <section className={cx(position)}>
                <div className={cx('title_wrap')}>
                    <div className={cx('title')}>{title}</div>
                    <div className={cx('title_en')}>{titleEn}</div>
                </div>
                <div className={cx('picture_wrap')}>
                    <div className={cx('picture')} style={{backgroundImage: `url(${img[3]})`}}/>
                    <div className={cx('picture')} style={{backgroundImage: `url(${img[0]})`}}/>
                    <div className={cx('picture')} style={{backgroundImage: `url(${img[1]})`}}/>
                    <div className={cx('picture')} style={{backgroundImage: `url(${img[2]})`}}/>
                </div>
            </section>
        );
    }
}

const Performance = () => {
    return (
        <div className={cx(!isMobile ? 'Performance' : 'Performance_mobile')}>
            {
                PerformanceData.map(data => {
                    return (
                        <Section
                            position={data.position}
                            title={data.title}
                            titleEn={data.titleEn}
                            background={data.background}
                            img={data.img}
                        />
                    );
                })
            }
        </div>
    );
}

export default Performance;