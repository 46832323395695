import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { slide as Menu } from "react-burger-menu";

import styles from "./Header.scss";
import classNames from "classnames";

const cx = classNames.bind(styles);

const LogoBlue = process.env.REACT_APP_IMAGE_URL + "/logo_color.png";
const LogoWhite = process.env.REACT_APP_IMAGE_URL + "/logo_white.png";
const PublicSite = process.env.REACT_APP_IMAGE_URL + "/scj_public_white.svg";
const HMBC = process.env.REACT_APP_IMAGE_URL + "/HMBC_white.svg";
const VolunteerSite = process.env.REACT_APP_IMAGE_URL + "/scjvolunteers_white.svg";
const Podbbang = process.env.REACT_APP_IMAGE_URL + "/podbbang_white.svg";
const WordSquare = process.env.REACT_APP_IMAGE_URL + "/wordsquare_white.svg";

const SubMenu = ({ element, location }) => {
  return (
    <Link to={element.link} className={cx({ "menu-item-sub": isMobile }, { active: location.pathname === element.link })}>
      {element.name}
    </Link>
  );
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/")[1];

  // PC는 서브메뉴 기본적으로 false, 모바일은 현재 페이지의 서브메뉴 오픈
  const [introSubMenu, setIntroSubMenu] = useState(!isMobile ? false : path === "intro");
  const [churchSubMenu, setChurchSubMenu] = useState(!isMobile ? false : path === "church");
  const [volunteerSubMenu, setVolunteerSubMenu] = useState(!isMobile ? false : path === "volunteer");
  const [marketingSubMenu, setMarketingSubMenu] = useState(!isMobile ? false : path === "marketing");
  const [familySiteSubMenu, setFamilySiteSubMenu] = useState(false);

  const subMenu = {
    intro: [
      { link: "/intro/greetings", name: "인사말씀" },
      { link: "/intro/vision", name: "비전" },
      { link: "/intro/history", name: "연혁" },
      { link: "/intro/symbol", name: "상징" },
      { link: "/intro/regions", name: "지경 소개" },
    ],
    church: [
      { link: "/church/seoul", name: "서울교회" },
      { link: "/church/pocheon", name: "포천교회" },
      { link: "/church/guri", name: "구리교회" },
      { link: "/church/dongdaemun", name: "동대문교회" },
      { link: "/church/uijung", name: "의정부교회" },
    ],
    volunteer: [
      { link: "/volunteer/neighbor", name: "이웃 사랑" },
      { link: "/volunteer/nation", name: "나라 사랑" },
      { link: "/volunteer/corona", name: "코로나 위기극복" },
      { link: "/volunteer/prize", name: "수상 이력" },
    ],
    marketing: !isMobile
      ? [
          { link: "/relation-center", name: "홍보관" },
          { link: "/marketing/graduation", name: "10만 수료식" },
          { link: "/marketing/culture", name: "하늘문화" },
          { link: "/marketing/press", name: "언론보도" },
        ]
      : [
          { link: "/relation-center", name: "홍보관" },
          { link: "/marketing/seminar", name: "2023 말씀대성회" },
          { link: "/marketing/graduation", name: "10만 수료식" },
          { link: "/marketing/culture", name: "하늘문화" },
          { link: "/marketing/press", name: "언론보도" },
        ],
    familySite: [
      { link: "https://www.shincheonji.kr/", name: "신천지 공식 사이트" },
      { link: "https://www.youtube.com/channel/UCHFR84nup5zCrmM384wbBDQ", name: "HMBC 신천지 하늘문화방송" },
      { link: "https://www.scjvolunteers.com/", name: "신천지 자원봉사단" },
      { link: "https://www.podbbang.com/channels/14531", name: "신천지 공식 팟캐스트" },
      { link: "https://wordsquare.org/", name: "말씀광장" },
    ],
  };

  const handleSubMenu = (name) => {
    setIntroSubMenu(false);
    setChurchSubMenu(false);
    setVolunteerSubMenu(false);
    setMarketingSubMenu(false);

    switch (name) {
      case "intro":
        setIntroSubMenu(!introSubMenu);
        break;
      case "church":
        setChurchSubMenu(!churchSubMenu);
        break;
      case "volunteer":
        setVolunteerSubMenu(!volunteerSubMenu);
        break;
      case "marketing":
        setMarketingSubMenu(!marketingSubMenu);
        break;
      default:
        break;
    }
  };

  return (
    <div className={cx(!isMobile ? "Header" : "Header_mobile")}>
      <div className={cx("logo_blue")} onClick={() => navigate("/")}>
        <img src={LogoBlue} alt={""} />
      </div>

      {!isMobile ? (
        // PC GNB
        <div className={cx("nav")}>
          <Link to={"/intro/greetings"} className={cx("item", { active: path === "intro" })} onMouseEnter={() => setIntroSubMenu(true)} onMouseLeave={() => setIntroSubMenu(false)}>
            지파소개
            <div className={cx("sub_menu", { active: introSubMenu })}>
              {subMenu.intro.map((element, i) => (
                <SubMenu element={element} key={i} location={location} />
              ))}
            </div>
          </Link>

          <Link to={"/church/seoul"} className={cx("item", { active: path === "church" })} onMouseEnter={() => setChurchSubMenu(true)} onMouseLeave={() => setChurchSubMenu(false)}>
            교회소개
            <div className={cx("sub_menu", { active: churchSubMenu })} onClick={() => setChurchSubMenu(false)}>
              {subMenu.church.map((element, i) => (
                <SubMenu element={element} key={i} location={location} />
              ))}
            </div>
          </Link>

          <Link to={"/volunteer/neighbor"} className={cx("item", { active: path === "volunteer" })} onMouseEnter={() => setVolunteerSubMenu(true)} onMouseLeave={() => setVolunteerSubMenu(false)}>
            사회공헌
            <div className={cx("sub_menu", { active: volunteerSubMenu })}>
              {subMenu.volunteer.map((element, i) => (
                <SubMenu element={element} key={i} location={location} />
              ))}
            </div>
          </Link>

          <Link to={"/marketing/graduation"} className={cx("item", { active: path === "marketing" })} onMouseEnter={() => setMarketingSubMenu(true)} onMouseLeave={() => setMarketingSubMenu(false)}>
            교회홍보
            <div className={cx("sub_menu", { active: marketingSubMenu })}>
              {subMenu.marketing.map((element, i) => (
                <SubMenu element={element} key={i} location={location} />
              ))}
            </div>
          </Link>

          <Link to={"/bluesky"} className={cx("item", { active: path === "bluesky" })}>
            파란하늘TV
          </Link>

          <Link to={"/direction"} className={cx("item", { active: path === "direction" })}>
            오시는길
          </Link>

          <button className={cx("family_site_btn")} onClick={() => setFamilySiteSubMenu(!familySiteSubMenu)}>
            패밀리사이트
            <div className={cx("sub_menu", { active: familySiteSubMenu })}>
              {subMenu.familySite.map((element, i) => (
                <a href={element.link} key={i} target="_blank" rel="noreferrer">
                  {element.name}
                </a>
              ))}
            </div>
          </button>
        </div>
      ) : (
        // 모바일 사이드메뉴
        <Menu>
          <div className={cx("logo_white")} onClick={() => navigate("/")}>
            <img src={LogoWhite} alt={""} />
          </div>

          <div className={cx("menu-item")} onClick={() => handleSubMenu("intro")}>
            지파소개
          </div>
          <ul className={cx("sub-menu-wrap", "five", { open: introSubMenu })}>
            {subMenu.intro.map((element, i) => (
              <SubMenu element={element} key={i} location={location} />
            ))}
          </ul>

          <div className={cx("menu-item")} onClick={() => handleSubMenu("church")}>
            교회소개
          </div>
          <ul className={cx("sub-menu-wrap", "five", { open: churchSubMenu })}>
            {subMenu.church.map((element, i) => (
              <a key={i} href={element.link} className={cx("menu-item-sub", { active: location.pathname === element.link })}>
                {element.name}
              </a>
            ))}
          </ul>

          <div className={cx("menu-item")} onClick={() => handleSubMenu("volunteer")}>
            사회공헌
          </div>
          <ul className={cx("sub-menu-wrap", "four", { open: volunteerSubMenu })}>
            {subMenu.volunteer.map((element, i) => (
              <SubMenu element={element} key={i} location={location} />
            ))}
          </ul>

          <div className={cx("menu-item")} onClick={() => handleSubMenu("marketing")}>
            교회홍보
          </div>
          <ul className={cx("sub-menu-wrap", "five", { open: marketingSubMenu })}>
            {subMenu.marketing.map((element, i) => (
              <SubMenu element={element} key={i} location={location} />
            ))}
          </ul>

          <div className={cx("menu-item")} onClick={() => navigate("/bluesky")}>
            파란하늘TV
          </div>

          <div className={cx("menu-item")} onClick={() => navigate("/direction")}>
            오시는길
          </div>

          <div className={cx("site_wrap")}>
            <img src={PublicSite} alt={""} className={cx("site_icon")} onClick={() => (window.location.href = "https://www.shincheonji.kr/")} />
            <img src={HMBC} alt={""} className={cx("site_icon")} onClick={() => (window.location.href = "https://www.youtube.com/channel/UCHFR84nup5zCrmM384wbBDQ")} />
            <img src={VolunteerSite} alt={""} className={cx("site_icon")} onClick={() => (window.location.href = "https://www.scjvolunteers.com/")} />
            <img src={Podbbang} alt={""} className={cx("site_icon")} onClick={() => (window.location.href = "https://www.podbbang.com/channels/14531")} />
            <img src={WordSquare} alt={""} className={cx("site_icon")} onClick={() => (window.location.href = "https://wordsquare.org/")} />
          </div>
        </Menu>
      )}
    </div>
  );
};

export default Header;
