import React, { Fragment } from "react";
import { relationNavigation } from "../relationNavigation";
import { useNavigate } from "react-router-dom";

import styles from "./RelationCenterHistory.scss";
import classNames from "classnames";
import ImageRotator from "../components/RelationCenterMain/ImageRotator";
import { imageURL } from "../libs/image";

const cx = classNames.bind(styles);

const thumbnails = {
  intro: imageURL + "/hongbo-0-main.jpg",
  media: imageURL + "/hongbo-1-main.jpg",
  books: imageURL + "/hongbo-2-main.jpg",
  shincheonji: imageURL + "/hongbo-3-main.jpg",
  "twelve-tribe": imageURL + "/hongbo-4-main.jpg",
  eduzion: imageURL + "/hongbo-5-main.jpg",
  earth: imageURL + "/hongbo-6-main.jpg",
};

const ROTATE_IMAGES = [
  imageURL + "/hongbo-0-main.jpg",
  imageURL + "/hongbo-1.jpg",
  imageURL + "/hongbo-2-main.jpg",
  imageURL + "/hongbo-3-main.jpg",
  imageURL + "/hongbo-4-main.jpg",
  imageURL + "/hongbo-5-main.jpg",
  imageURL + "/hongbo-6-main.jpg",
];

const RelationCenterHistory = () => {
  const navigate = useNavigate();

  const handleClickItem = (href) => () => {
    navigate(href);
  };

  return (
    <section className={cx("relation-center-history")}>
      <ImageRotator srcs={ROTATE_IMAGES} delay={5000} duration={2000} className={cx("image-rotator")} />
      <ul className={cx("history-list")}>
        {Object.entries(relationNavigation["history"]).map(([url, value]) => {
          if (url === "component" || url === "title") {
            return <Fragment key={url} />;
          }
          return (
            <li key={url} className={cx("history-list-item")} onClick={handleClickItem(`/relation-center/history/${url}`)}>
              <span>{value.title}</span>
              <img src={thumbnails[url]} style={{ height: "100%", width: "100%", objectFit: "cover" }} alt="" />
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default RelationCenterHistory;
