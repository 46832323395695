import React, {useState, useEffect} from "react";
import Layout from "../Layout/Layout";
import PageTitle from "../PageTitle/PageTitle";
import {isMobile} from "react-device-detect";

import styles from './Direction.scss';
import classNames from "classnames";

const cx = classNames.bind(styles);

const Direction = () => {

    const ArrowImg = process.env.REACT_APP_IMAGE_URL + '/direction/arrow.png';
    const BusImg = process.env.REACT_APP_IMAGE_URL + '/direction/bus.png';

    const [church, setChurch] = useState('seoul');

    useEffect(() => {
        const width = !isMobile ? "1200" : "400";
        const height = !isMobile ? "600" : "350";
        switch (church) {
            case 'seoul':
                new window.daum.roughmap.Lander({
                    "timestamp" : "1657283616885",
                    "key" : "2awco",
                    "mapWidth" : width,
                    "mapHeight" : height
                }).render();
                break;
            case 'pocheon':
                new window.daum.roughmap.Lander({
                    "timestamp" : "1657459199722",
                    "key" : "2awje",
                    "mapWidth" : width,
                    "mapHeight" : height
                }).render();
                break;
            case 'guri':
                new window.daum.roughmap.Lander({
                    "timestamp" : "1657459814220",
                    "key" : "2awjf",
                    "mapWidth" : width,
                    "mapHeight" : height
                }).render();
                break;
            case 'dongdaemun':
                new window.daum.roughmap.Lander({
                    "timestamp" : "1657459974553",
                    "key" : "2awjh",
                    "mapWidth" : width,
                    "mapHeight" : height
                }).render();
                break;
            case 'uijung':
                new window.daum.roughmap.Lander({
                    "timestamp" : "1657460035797",
                    "key" : "2awji",
                    "mapWidth" : width,
                    "mapHeight" : height
                }).render();
                break;
            default:
                break;
        }
    }, [church])

    return (
        <Layout>
            <PageTitle title='오시는 길' />

            <div className={cx(!isMobile ? 'Direction' : 'Direction_mobile')}>

                <div className={cx('church_btn_wrap')}>
                    <div className={cx('church_btn_box', {active: church === 'seoul'})}>
                        <button
                            className={cx('church_btn')}
                            onClick={() => setChurch('seoul')}
                        >
                            {!isMobile ? '서울교회' : '서울'}
                        </button>
                    </div>
                    <div className={cx('church_btn_box', {active: church === 'pocheon'})}>
                        <button
                            className={cx('church_btn')}
                            onClick={() => setChurch('pocheon')}
                        >
                            {!isMobile ? '포천교회' : '포천'}
                        </button>
                    </div>
                    <div className={cx('church_btn_box', {active: church === 'guri'})}>
                        <button
                            className={cx('church_btn')}
                            onClick={() => setChurch('guri')}
                        >
                            {!isMobile ? '구리교회' : '구리'}
                        </button>
                    </div>
                    <div className={cx('church_btn_box', {active: church === 'dongdaemun'})}>
                        <button
                            className={cx('church_btn')}
                            onClick={() => setChurch('dongdaemun')}
                        >
                            {!isMobile ? '동대문교회' : '동대문'}
                        </button>
                    </div>
                    <div className={cx('church_btn_box', {active: church === 'uijung'})}>
                        <button
                            className={cx('church_btn')}
                            onClick={() => setChurch('uijung')}
                        >
                            {!isMobile ? '의정부교회' : '의정부'}
                        </button>
                    </div>
               </div>

                <div className={cx('map')}>
                    { church === 'seoul' && <div id="daumRoughmapContainer1657283616885" className="root_daum_roughmap root_daum_roughmap_landing"/> }
                    { church === 'pocheon' && <div id="daumRoughmapContainer1657459199722" className="root_daum_roughmap root_daum_roughmap_landing"/> }
                    { church === 'guri' && <div id="daumRoughmapContainer1657459814220" className="root_daum_roughmap root_daum_roughmap_landing"/> }
                    { church === 'dongdaemun' && <div id="daumRoughmapContainer1657459974553" className="root_daum_roughmap root_daum_roughmap_landing"/> }
                    { church === 'uijung' && <div id="daumRoughmapContainer1657460035797" className="root_daum_roughmap root_daum_roughmap_landing"/> }
                    {
                        !isMobile &&
                        <div className={cx('welcome')}>
                            세상에 빛을 비추는, 서울야고보 지파에 오신 것을 환영합니다.
                            <img src={ArrowImg} alt=''/>
                            <span>
                                {church === 'seoul' && '서울교회'}
                                {church === 'pocheon' && '포천교회'}
                                {church === 'guri' && '구리교회'}
                                {church === 'dongdaemun' && '동대문교회'}
                                {church === 'uijung' && '의정부교회'}
                            </span>
                        </div>
                    }
                </div>

                {
                    church === 'seoul' &&
                    <article className={cx('desc_wrap')}>
                        <section className={cx('left_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    지하철 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    7호선 중계역 1번 출구에서 176m
                                </div>
                            </div>

                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    자가용 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    서울시 노원구 동일로 1323 하라프라자<br/>
                                    하라프라자 건물 주차장 이용<br/>
                                    <br/>
                                    <span style={{color: '#e52b25'}}>※ 교통이 혼잡하니 대중교통을 이용하시면 편리합니다.</span>
                                </div>
                            </div>
                        </section>

                        <section className={cx('right_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    버스 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    <li>
                                        <div className={cx('square')}/>상계주공1단지 하차(도보 30초)
                                    </li>

                                    <br/>

                                    <li>
                                        <div className={cx('square')}/>백병원 하차(도보 1분)
                                    </li>

                                    <br/>

                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            101, 105, 111, 146<br/>
                                            1137, 1139<br/>
                                            노원15<br/>
                                            <span style={{color: '#e52b25'}}>3100, 3200, 3500, 3600</span><br/>
                                            <span style={{color: '#8f4a19'}}>6100</span><br/>
                                            N13(심야), N61(심야)
                                        </div>
                                    </li>
                                </div>
                            </div>
                        </section>
                    </article>
                }

                {
                    church === 'pocheon' &&
                    <article className={cx('desc_wrap')}>
                        <section className={cx('left_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    자가용 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    경기도 포천시 호국로 959 엘카운티 A동 2층 다호(대성전)<br/>
                                    햇빛요양원 건물 2층
                                </div>
                            </div>
                        </section>

                        <section className={cx('right_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    버스 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    <li>
                                        <div className={cx('square')}/>선단4통 장승거리
                                    </li>
                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            67, 72, 72-3, 88, 88-1, 56-1,<br/>
                                            1-1, 10-2, 85, 85-1, 87-3<br/>
                                            <span style={{color: '#e52b25'}}>1386, 3006</span><br/>
                                            <span style={{color: '#0067b2'}}>138, 138-5</span><br/>
                                        </div>
                                    </li>

                                    <br/>

                                    <li>
                                        <div className={cx('square')}/>선단1통. 대진대학교
                                    </li>
                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            10-2, 56-1, 63, 67, 72, 72-3<br/>
                                            8, 85, 85-1, 87-3, 88, 88-1<br/>
                                            <span style={{color: '#e52b25'}}>3200, 3006, 1386, 1403</span><br/>
                                            <span style={{color: '#0067b2'}}>138, 138-5</span><br/>
                                            <span style={{color: '#7f4f87'}}>3001, 3002</span><br/>
                                        </div>
                                    </li>

                                    <br/>

                                    <li>
                                        <div className={cx('square')}/>삼보주택
                                    </li>
                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            50, 63, 69, 8, 1, 1-1
                                        </div>
                                    </li>
                                </div>
                            </div>
                        </section>
                    </article>
                }

                {
                    church === 'guri' &&
                    <article className={cx('desc_wrap')}>
                        <section className={cx('left_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    지하철 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    경기중앙선 도농역 2번 출구에서 1.1km(도보20분)
                                </div>
                            </div>

                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    자가용 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    남양주시 다산지금로 16번길 87 강산타워 4층
                                </div>
                            </div>
                        </section>

                        <section className={cx('right_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    버스 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    <li>
                                        <div className={cx('square')}/>
                                        남양주 제2청사. 남양주 보건소.<br/>
                                        남양주 남부 경찰서.<br/>
                                        에일린의뜰 아파트 정문
                                    </li>

                                    <br/>

                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            167, 23, 65, 9, 1-4, 10-5, 15,<br/>
                                            165, 167-1, 2000-1, 30, 65-1,<br/>
                                            76, 9-1, 91, 93, 97, 38<br/>
                                            땡큐11, 땡큐50<br/>
                                            <span style={{color: '#e52b25'}}>
                                                1100, 1200, 1200-1, 1330-2,<br/>
                                                1330-3, 1330-4, 1330-44<br/>
                                                1000, 1003, 1006
                                            </span><br/>
                                        </div>
                                    </li>
                                </div>
                            </div>
                        </section>
                    </article>
                }

                {
                    church === 'dongdaemun' &&
                    <article className={cx('desc_wrap')}>
                        <section className={cx('left_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    지하철 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    1호선 제기동역 2번 출구에서 334m(도보6분)<br/>
                                    1호선 청량리역 6번 출구에서 346m(도보6분)
                                </div>
                            </div>

                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    자가용 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    서울시 동대문구 왕산로 147 경동빌딩 3층<br/>
                                    <br/>
                                    <span style={{color: '#e52b25'}}>※ 교통이 혼잡하니 대중교통을 이용하시면 편리합니다.</span>
                                </div>
                            </div>
                        </section>

                        <section className={cx('right_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    버스 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    <li>
                                        <div className={cx('square')}/>
                                        청량리수산시장(06418)
                                    </li>

                                    <br/>

                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            <span style={{color: '#0067b2'}}>201, 121</span><br/>
                                            1213, 1222, 1224, 1227, 2115
                                        </div>
                                    </li>

                                    <br/>

                                    <li>
                                        <div className={cx('square')}/>
                                        청량리수산시장(06426)
                                    </li>

                                    <br/>

                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            <span style={{color: '#0067b2'}}>
                                                105, 120, 147, 202, 241, 260, 261,<br/>
                                                262, 270, 271, 272, 420, 720
                                            </span><br/>
                                            N13(심야), N26(심야)<br/>
                                            2221, 2233
                                        </div>
                                    </li>

                                    <br/>

                                    <li>
                                        <div className={cx('square')}/>
                                        청량리. 청과물 도매시장(중)
                                    </li>

                                    <br/>

                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            <span style={{color: '#0067b2'}}>
                                                105, 147, 201, 202, 241, 260, 261,<br/>
                                                262, 270, 271, 272, 420, 720
                                            </span><br/>
                                            N13(심야), N26(심야)<br/>
                                            1226, 2115, 2221, 2233<br/>
                                            <span style={{color: '#e52b25'}}>3200</span>
                                        </div>
                                    </li>
                                </div>
                            </div>
                        </section>
                    </article>
                }

                {
                    church === 'uijung' &&
                    <article className={cx('desc_wrap')}>
                        <section className={cx('left_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    지하철 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    1호선 망월사역 1번 출구에서 196m(도보 3분)
                                </div>
                            </div>

                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    자가용 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    경기도 의정부시 평화로 224 2층<br/>
                                    <br/>
                                    <span style={{color: '#e52b25'}}>※ 교통이 혼잡하니 대중교통을 이용하시면 편리합니다.</span>
                                </div>
                            </div>
                        </section>

                        <section className={cx('right_section')}>
                            <div className={cx('desc_box')}>
                                <div className={cx('desc_title')}>
                                    버스 이용 시
                                </div>
                                <div className={cx('desc_content')}>
                                    <li>
                                        <div className={cx('square')}/>
                                        안말입구. 망월사역 북문(마을)
                                    </li>

                                    <br/>

                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            202-1, 203, 203-1, 203-2
                                        </div>
                                    </li>

                                    <br/>

                                    <li>
                                        <div className={cx('square')}/>
                                        안말. 병무청. 호원예비군 훈련장
                                    </li>

                                    <br/>

                                    <li className={cx('li_bus_wrap')}>
                                        <img src={BusImg} alt={''} />
                                        <div className={cx('li_bus_list')}>
                                            118, 133, 39, 56, 7, 72, 72-3, 36<br/>
                                            <span style={{color: '#0067b2'}}>106</span><br/>
                                            202-1, 203, 203-1, 203-2, 206-1
                                        </div>
                                    </li>
                                </div>
                            </div>
                        </section>
                    </article>
                }
            </div>
        </Layout>
    );
}

export default Direction;
